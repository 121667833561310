export type TrustArcCookieData = {
  cookiePreference?: string;
  type: 'functional' | 'advertising';
};

export function isTrustarcCookieTypeAllowed({ cookiePreference, type }: TrustArcCookieData) {
  if (!cookiePreference) return true;

  const typeValue = type === 'functional' ? '1' : '2';
  return cookiePreference.includes(typeValue);
}
