import React from 'react';
import Link from 'components/link';
import ImageLoader from 'components/image-loader';
import InstagramIcon from 'components/icon/instagram-icon';
import LinkedInIcon from 'components/icon/linkedin-icon';
import styles from './commercialFooterStyle.module.scss';
import Youtube from '@mui/icons-material/YouTube';
import Facebook from '@mui/icons-material/FacebookOutlined';
import { useThemeContext } from 'contexts/theme';

export default function ExpRealtyFooter() {
  const { theme } = useThemeContext();

  return (
    <footer className={styles.footer}>
      <div className={styles['footer-top']}>
        {/* SECTION 1 LOGO */}
        <div className={styles['footer-logo-container']}>
          <ImageLoader
            className={styles['footer-logo']}
            src="/next/assets/images/exp-commercial/eXp_Commercial_logo.png"
            alt="eXp Commercial Logo"
            width="87"
            height="45"
          />
          <p className={styles['company-description']}>
          eXp Commercial is an eXp World Holdings, Inc. (Nasdaq: EXPI) company.
          </p>
        </div>
        <div className={styles['footer-links-container']}>
          <div className={styles['footer-links']}>
            {theme?.footerContent?.column1.map(({ isAnchor, link, target, label }, index) =>
              isAnchor ? (
                <a key={`column1-${index}`} href={link} target={target} rel="noreferrer">
                  {label}
                </a>
              ) : (
                <Link key={`column1-${index}`} href={link} target={target}>
                  {label}
                </Link>
              )
            )}
          </div>
          <div className={styles['footer-links']}>
            {theme?.footerContent?.column2.map(({ link, target, label }, index) =>
              <Link key={`column1-${index}`} href={link} target={target}>
                {label}
              </Link>
            )}
          </div>
        </div>
        <div className={styles['social-column']}>
          <div className={styles.socials}>
            <a
              href={theme.organization.facebookUrl}
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook"
            >
              <Facebook className={styles['social-icon']} />
            </a>
            <a
              href={theme.organization.linkedinUrl}
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
            >
              <LinkedInIcon className={styles['social-icon']} />
            </a>
            <a
              href={theme.organization.youtubeUrl}
              target="_blank"
              rel="noreferrer"
              aria-label="YouTube"
            >
              <Youtube className={styles['social-icon']} />
            </a>
            <a
              href={theme.organization.instagramUrl}
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
            >
              <InstagramIcon className={styles['social-icon']} />
            </a>
          </div>
          <ExpDynamicCopyright />
        </div>
      </div>

      {/* FOOTER MIDDLE SECTION */}

      <div className={styles['footer-middle']}>
        <div className={styles['footer-disclaimers']}>
          <h4>Realty:</h4>
          {theme?.footerContent?.bottom.map((section, index) => {
            return (
              <p key={index}>
                {section.map(({ isAnchor, link, target, label }, index) => (
                  isAnchor ? (
                    <a key={index} href={link} target={target} rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    <span key={index}>{label}</span>
                  )
                ))}
              </p>
            );
          })}

          <h4>Commercial:</h4>
          <p>eXp Commercial of California, Inc. | CA DRE# 01208047 | Jeffrey Peldon – Designated Broker</p>
          <p>Clifford Bogart – Designated Broker – TX</p>
          {theme?.footerContent?.bottom.slice(0, 2).map((section, index) => (
            <p key={index}>
              {section.map(({ isAnchor, link, target, label }, idx) =>
                isAnchor ? (
                  <a key={idx} href={link} target={target} rel="noreferrer">
                    {label}
                  </a>
                ) : (
                  <span key={idx}>{label}</span>
                )
              )}
            </p>
          ))}
        </div>
      </div>

      <div className={styles['footer-bottom']}>
        {theme?.footerContent?.column3.map(({ link, target, label }, index) =>
          <Link key={`column3-${index}`} href={link} target={target}>
            {label}
          </Link>
        )}
      </div>
    </footer>
  );
}

const ExpDynamicCopyright = () => (
  <p>Copyright © {new Date().getFullYear()} eXp Realty. eXp World Holdings, Inc. All Rights Reserved</p>
);
