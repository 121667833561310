
import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import { trackEvent } from 'utils/google-tag-manager';
import storage from 'utils/storage';
import { useFeaturesContext } from './features';

import type Listing from 'data/listing';
import type { ChatMessage } from 'components/chat-widget/ChatMessage';

type OpenChatSource = {
  source: 'Bubble' | 'Menu' | 'Widget';
};

type ChatPopupStorage = { expiry: string } | undefined;

const ChatContext = createContext({});
export type PageContentType = 'Listing' | 'Area' | 'Map' | 'General';

export interface PageContent<T> {
  type: PageContentType;
  content: T;
}

export interface ListingPageContent extends PageContent<Listing> {
  type: 'Listing';
  isPrivate: boolean;
}

export interface IChatContext {
  pageContent?: ListingPageContent | null;
  setPageContent: (content: ListingPageContent | null) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  hasInteracted: boolean;
  closePopup: boolean;
  setClosePopup: (closePopup: boolean) => void;
  openChat: (source: OpenChatSource) => void;
  closeThePopup: (event: any) => void;
  messages: ChatMessage[];
  setMessages: (messages: React.SetStateAction<ChatMessage<any>[]>) => void;
  preventPopup: boolean;
}

interface Props {
  children: ReactNode;
}

const CHAT_POPUP_KEY = 'chat-popup';

export default function ChatContextProvider({ children }: Props) {
  const { setFeatures } = useFeaturesContext();
  const [pageContent, setPageContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [closePopup, setClosePopup] = useState(true);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [preventPopup, setPreventPopup] = useState(false);

  const openChat = ({ source }: OpenChatSource) => {
    setFeatures(prev => ({ ...prev, useSmartChat: true }));
    setIsOpen(true);
    setHasInteracted(true);

    if (closePopup && source === 'Widget') {
      trackEvent('UiAllSChatAvaClick');
    } else if (source === 'Bubble') {
      trackEvent('UiAllSChatBubOpen');
    } else if (source === 'Menu') {
      trackEvent('UiAllMenuSChatOpen');
    }
  };

  const closeThePopup = (event: any) => {
    trackEvent('UiAllSChatBubClose');
    event.stopPropagation();
    setClosePopup(true);
    setHasInteracted(true);

    handlePreventPopup();
  };

  const handlePreventPopup = () => {
    const expiredDate = new Date().setDate(new Date().getDate() + 7);
    storage.set(CHAT_POPUP_KEY, { expiry: expiredDate });
    setPreventPopup(true);
  };

  const initialPopupPreventionCheck = () => {
    const chatPopup = storage.get(CHAT_POPUP_KEY) as ChatPopupStorage;
    const isNotExpired = chatPopup && new Date(chatPopup.expiry) > new Date();
    if (isNotExpired) {
      setPreventPopup(true);
    }
  };

  useEffect(() => {
    initialPopupPreventionCheck();
  }, []);

  return (
    <ChatContext.Provider value={{ pageContent, setPageContent, isOpen, setIsOpen, hasInteracted, closePopup, setClosePopup, openChat, closeThePopup, messages, setMessages, preventPopup }}>
      {children}
    </ChatContext.Provider>
  );
}

function useChat(): IChatContext {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatContextProvider');
  }
  return context as Required<IChatContext>;
}

export { useChat };