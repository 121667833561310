import GoogleMapsApiLoader from './loader';

async function getGeocoder() {
  const { Geocoder } = await GoogleMapsApiLoader.importLibrary('geocoding');
  return new Geocoder();
}

function createGeocoderRequest(
  placeId: string) {
  const request: google.maps.GeocoderRequest = { placeId: placeId };
  return request;
}

function createGeocodeAddressRequest(address: string) {
  const request: google.maps.GeocoderRequest = { address: address };
  return request;
}


export default async function geocode(placeId: string) {

  const geocoder = await getGeocoder();

  if (geocoder) {
    const request = createGeocoderRequest(placeId);
    const response = await geocoder.geocode(request);

    if (response?.results) {
      return response.results[0];
    }
      
  }

  return undefined;
}

export async function getPositionFromAddress(address: string): Promise<google.maps.GeocoderResult | undefined> {
  const geocoder = await getGeocoder();

  if (geocoder) {
    const request = createGeocodeAddressRequest(address);
    const response = await geocoder.geocode(request);

    if (response?.results && response.results.length > 0) {
      return response.results[0];
    }
      
  }
  return undefined;
}