import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import { dasherizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import { swapKeysAndValues } from '@zoocasa/node-kit/objects/swap-keys-and-values';
import { CountryCode, CountryCodeList } from 'types/countries';


export type CanadaProvinceCode = 'on' | 'pe' | 'qc' | 'nl' | 'ns' | 'bc' | 'mb' | 'ab' | 'sk' | 'nt' | 'nb' | 'nu' | 'yt';
export type CanadaProvince = 'Ontario' | 'Prince Edward Island' | 'Quebec' | 'Newfoundland and Labrador' | 'Nova Scotia' | 'British Columbia' | 'Manitoba' | 'Alberta' | 'Saskatchewan' | 'Northwest Territories' | 'New Brunswick' | 'Nunavut' | 'Yukon';

export type UsaStateCode = 'al' | 'ak' | 'as' | 'az' | 'ar' | 'ca' | 'co' | 'ct' | 'de' | 'dc' | 'fm' | 'fl' | 'ga' | 'gu' | 'hi' | 'id' | 'il' | 'in' | 'ia' | 'ks' | 'ky' | 'la' | 'me' | 'mh' | 'md' | 'ma' | 'mi' | 'mn' | 'ms' | 'mo' | 'mt' | 'ne' | 'nv' | 'nh' | 'nj' | 'nm' | 'ny' | 'nc' | 'nd' | 'mp' | 'oh' | 'ok' | 'or' | 'pw' | 'pa' | 'pr' | 'ri' | 'sc' | 'sd' | 'tn' | 'tx' | 'ut' | 'vt' | 'vi' | 'va' | 'wa' | 'wv' | 'wi' | 'wy';
export type UsaState = 'Alabama' | 'Alaska' | 'American Samoa' | 'Arizona' | 'Arkansas' | 'California' | 'Colorado' | 'Connecticut' | 'Delaware' | 'District Of Columbia' | 'Federated States Of Micronesia' | 'Florida' | 'Georgia' | 'Guam' | 'Hawaii' | 'Idaho' | 'Illinois' | 'Indiana' | 'Iowa' | 'Kansas' | 'Kentucky' | 'Louisiana' | 'Maine' | 'Marshall Islands' | 'Maryland' | 'Massachusetts' | 'Michigan' | 'Minnesota' | 'Mississippi' | 'Missouri' | 'Montana' | 'Nebraska' | 'Nevada' | 'New Hampshire' | 'New Jersey' | 'New Mexico' | 'New York' | 'North Carolina' | 'North Dakota' | 'Northern Mariana Islands' | 'Ohio' | 'Oklahoma' | 'Oregon' | 'Palau' | 'Pennsylvania' | 'Puerto Rico' | 'Rhode Island' | 'South Carolina' | 'South Dakota' | 'Tennessee' | 'Texas' | 'Utah' | 'Vermont' | 'Virgin Islands' | 'Virginia' | 'Washington' | 'West Virginia' | 'Wisconsin' | 'Wyoming';
export type ProvinceOrStateCode = CanadaProvinceCode | UsaStateCode;
export type ProvinceOrState = CanadaProvince | UsaState;
export enum ProvinceAndState {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NS = 'NS',
  NT = 'NT',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
  AL = 'AL',
  AK = 'AK',
  AS = 'AS',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  MP = 'MP',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

const provinceMappings: Record<CanadaProvinceCode, CanadaProvince> = {
  on: 'Ontario',
  pe: 'Prince Edward Island',
  qc: 'Quebec',
  nl: 'Newfoundland and Labrador',
  ns: 'Nova Scotia',
  bc: 'British Columbia',
  mb: 'Manitoba',
  ab: 'Alberta',
  sk: 'Saskatchewan',
  nt: 'Northwest Territories',
  nb: 'New Brunswick',
  nu: 'Nunavut',
  yt: 'Yukon',
};

const provinceNameMappings: Record<CanadaProvince, CanadaProvinceCode> = {
  Ontario: 'on',
  'Prince Edward Island': 'pe',
  Quebec: 'qc',
  'Newfoundland and Labrador': 'nl',
  'Nova Scotia': 'ns',
  'British Columbia': 'bc',
  Manitoba: 'mb',
  Alberta: 'ab',
  Saskatchewan: 'sk',
  'Northwest Territories': 'nt',
  'New Brunswick': 'nb',
  Nunavut: 'nu',
  Yukon: 'yt',
};

const stateMappings: Record<UsaStateCode, UsaState> = {
  al: 'Alabama',
  ak: 'Alaska',
  as: 'American Samoa',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  de: 'Delaware',
  dc: 'District Of Columbia',
  fm: 'Federated States Of Micronesia',
  fl: 'Florida',
  ga: 'Georgia',
  gu: 'Guam',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  mh: 'Marshall Islands',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  mp: 'Northern Mariana Islands',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pw: 'Palau',
  pa: 'Pennsylvania',
  pr: 'Puerto Rico',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  vi: 'Virgin Islands',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming',
};

const stateNameMappings: Record<UsaState, UsaStateCode> = {
  Alabama: 'al',
  Alaska: 'ak',
  'American Samoa': 'as',
  Arizona: 'az',
  Arkansas: 'ar',
  California: 'ca',
  Colorado: 'co',
  Connecticut: 'ct',
  Delaware: 'de',
  'District Of Columbia': 'dc',
  'Federated States Of Micronesia': 'fm',
  Florida: 'fl',
  Georgia: 'ga',
  Guam: 'gu',
  Hawaii: 'hi',
  Idaho: 'id',
  Illinois: 'il',
  Indiana: 'in',
  Iowa: 'ia',
  Kansas: 'ks',
  Kentucky: 'ky',
  Louisiana: 'la',
  Maine: 'me',
  'Marshall Islands': 'mh',
  Maryland: 'md',
  Massachusetts: 'ma',
  Michigan: 'mi',
  Minnesota: 'mn',
  Mississippi: 'ms',
  Missouri: 'mo',
  Montana: 'mt',
  Nebraska: 'ne',
  Nevada: 'nv',
  'New Hampshire': 'nh',
  'New Jersey': 'nj',
  'New Mexico': 'nm',
  'New York': 'ny',
  'North Carolina': 'nc',
  'North Dakota': 'nd',
  'Northern Mariana Islands': 'mp',
  Ohio: 'oh',
  Oklahoma: 'ok',
  Oregon: 'or',
  Palau: 'pw',
  Pennsylvania: 'pa',
  'Puerto Rico': 'pr',
  'Rhode Island': 'ri',
  'South Carolina': 'sc',
  'South Dakota': 'sd',
  Tennessee: 'tn',
  Texas: 'tx',
  Utah: 'ut',
  Vermont: 'vt',
  'Virgin Islands': 'vi',
  Virginia: 'va',
  Washington: 'wa',
  'West Virginia': 'wv',
  Wisconsin: 'wi',
  Wyoming: 'wy',
};

const provinceAndStateMappings = { ...provinceMappings, ...stateMappings };
const provinceAndStateNameMappings = { ...provinceNameMappings, ...stateNameMappings };

export const provinceAndStateCodes = Object.keys(provinceAndStateMappings) as ProvinceOrStateCode[];
export const provinceAndStateSlugs = Object.values(provinceAndStateMappings).map(name => dasherize(name));

export const usStateCodes = Object.keys(stateMappings) as UsaStateCode[];
export const canadaProvinceCodes = Object.keys(provinceMappings) as CanadaProvinceCode[];

export function provinceOrStateNameFromCode(code: ProvinceOrStateCode) {
  return provinceAndStateMappings[code];
}

export function provinceOrStateCodeFromName(name: ProvinceOrState) {
  return provinceAndStateNameMappings[name];
}

export function getProvinceOrStateCopy(provinceOrStateCode: string | null, city: string | null) {
  if (provinceOrStateCode) {
    if (city) {
      return provinceOrStateCode;
    } else {
      return provinceOrStateNameFromCode(provinceOrStateCode.toLowerCase() as ProvinceOrStateCode);
    }
  }
}

export function provinceOrStateCodeFromSlug(slug: string): ProvinceOrStateCode | null {
  const sanitizedSlug = slug?.trim()?.toLowerCase();
  const swappedMappings = dasherizeKeys(swapKeysAndValues(provinceAndStateMappings));
  return swappedMappings?.[sanitizedSlug] as ProvinceOrStateCode || null;
}

export function provinceOrStateSlugFromCode(code: ProvinceOrStateCode) {
  const name = provinceOrStateNameFromCode(code);
  return name ? dasherize(name) : null;
}

export function isUsStateCode(code: ProvinceOrStateCode): boolean {
  return usStateCodes.includes(code as any);
}

export function isCanadianProvinceCode(code: ProvinceOrStateCode): boolean {
  return canadaProvinceCodes.includes(code as any);
}

export function countryCodeFromProvinceOrState(name: string): CountryCode {
  if (name?.length > 0) {
    const isCanadianAdministrativeAreaLevel1Code = isCanadianProvinceCode(name.toLowerCase() as ProvinceOrStateCode);
    const isUsAdministrativeAreaLevel1Code = isUsStateCode(name.toLowerCase() as ProvinceOrStateCode);

    if (isCanadianAdministrativeAreaLevel1Code) {
      return CountryCodeList.CANADA;
    } else if (isUsAdministrativeAreaLevel1Code) {
      return CountryCodeList.UNITED_STATES;
    }
  }
  return CountryCodeList.CANADA;
}
