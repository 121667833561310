import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useIsNotMobile } from 'hooks/use-size-class';

export interface IPopupContext {
  popupPosition: any;
  setPopupPosition: any;
  setIsHoveringPin: any;
  setIsHoveringPopup: any;
  clusterPopupData: any;
  setClusterPopupData: any;
  schoolPopupData: any;
  setSchoolPopupData: any;
  closeSlideUp: () => void;
}

export const PopupContext = createContext<IPopupContext | Record<string, unknown>>({});
export function usePopupContext <T extends IPopupContext | Record<string, unknown> = IPopupContext>() {
  return useContext(PopupContext) as T;
}

interface Props {
  children: ReactNode;
}
export default function PopupContextProvider({ children }: Props) {
  const [isHoveringPin, setIsHoveringPin] = useState(false);
  const [isHoveringPopup, setIsHoveringPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState(false);
  const [delayRemoval, setDelayRemoval] = useState<number>();

  const [clusterPopupData, setClusterPopupData] = useState(null);
  const [schoolPopupData, setSchoolPopupData] = useState(null);
  const isNotMobile = useIsNotMobile();

  // Control popup
  useEffect(() => {
    if (isNotMobile) {
      if (isHoveringPin || isHoveringPopup) {
        clearTimeout(delayRemoval);
      } else {
        setDelayRemoval((setTimeout(() => {
          setPopupPosition(false);
          setClusterPopupData(null);
          setSchoolPopupData(null);
        }, 400) as unknown as number));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoveringPin, isHoveringPopup]);

  const closeSlideUp = () => {
    setClusterPopupData(null);
    setSchoolPopupData(null);
  };

  return (
    <PopupContext.Provider value={{
      popupPosition,
      setPopupPosition,
      setIsHoveringPin,
      setIsHoveringPopup,
      clusterPopupData,
      setClusterPopupData,
      schoolPopupData,
      setSchoolPopupData,
      closeSlideUp,
    }}>
      {children}
    </PopupContext.Provider>
  );
}
