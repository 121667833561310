import defaultSchoolParams from 'contexts/preferences/school/defaults';
import { getJSON } from 'utils/cookies';
import { storageKey } from 'contexts/preferences/school/utils';
import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';
import Cookies from 'js-cookie';

import type { SchoolParams as TSchoolParams } from 'contexts/preferences/school/types';

export default class SchoolParams {
  showSchools: boolean;
  filterListings: boolean;
  ratingType: 'eqao';
  showUnrated: boolean;
  eqaoRating: number;
  levelFilter: 'all' | 'elementary' | 'secondary';
  typeFilter: 'all' | 'public' | 'catholic';
  languageFilter: 'all' | 'english' | 'french';

  constructor(schoolParams: Record<string, unknown>) {
    Object.assign(this, schoolParams);
  }

  get filterParams() {
    const params = {
      level: this.levelFilter === 'all' ? null : this.levelFilter,
      language: this.languageFilter === 'all' ? null : this.languageFilter,
      unrated: this.showUnrated ? this.showUnrated : null,
      'min-eqao-rating': this.eqaoRating,
      'school-type': this.typeFilter === 'all' ? null : this.typeFilter,
    };
    return params;
  }
}

export function createSchoolParams(params?: TSchoolParams) {
  if (!params) {
    params = getJSON(Cookies.get(storageKey));
  }
  const properties = { ...defaultSchoolParams, ...params };
  const schoolParams = new SchoolParams(properties);
  return schoolParams;
}

export function updateSchoolParamsCookie(params: SchoolParams) {
  Cookies.set(storageKey, JSON.stringify(params), { expires: DEFAULT_EXPIRE_DAYS });
}
