import { removeCommasFromPrice } from './format-currency';

export default function formatNumber(value: number) {
  return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function convertFormattedNumberToInt(price: string | undefined) {
  return parseInt(removeCommasFromPrice(price) || '');
}

function isStringNumeric(str: string | undefined): boolean {
  if (str) {
    return /^[0-9]+$/.test(str);
  } else {
    return false;
  }
}

export { convertFormattedNumberToInt, isStringNumeric };
