import { getObjectFromRequestCookie } from './request-cookies';
import { POPULAR_CITIES_COOKIE } from 'constants/cookies';
import { IncomingMessage } from 'http';

import type { SearchSuggestions } from 'components/suggested-location-dropdown';
import type { IUserLocation } from 'utils/user-location';

export default function getPopularCitiesCookie(req?: IncomingMessage, userLocation?: IUserLocation) {
  if (userLocation && userLocation.slug) {
    const cities = getObjectFromRequestCookie<SearchSuggestions[]>(POPULAR_CITIES_COOKIE, req);

    try {
      const locationAdminAreaPath = `${userLocation.slug.slice(-2)}-real-estate`;
      const isPopularCitiesSameAsUserLocation = cities?.[0]?.urlPath?.endsWith(locationAdminAreaPath);

      if (isPopularCitiesSameAsUserLocation) {
        return cities;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return;
}
