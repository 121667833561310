import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import { useRouter } from 'next/router';
import configJSON from 'config.json';
import packageJSON from 'package.json';
import { useThemeContext } from 'contexts/theme';
import { ThemeNames } from 'types/themes';


export type HeadDataType = {
  title?: string;
  metaDescription?: string;
  metaKeywords?: string;
  noIndex?: boolean;
  canonicalUrl?: string;
  placePostalCode?: string;
  placeStreetAddress?: string;
  placeLocality?: string;
  placeRegion?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  schema?: Record<string, unknown>[];
};

export interface Props {
  data?: HeadDataType;
}

export default function HeadData({ data }: Props) {
  const { theme, themeName } = useThemeContext();
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;

  const properties = {
    iOSBannerHidden: false,
    title: theme?.title,
    metaDescription: theme?.metaDescription,
    metaKeywords: 'mls listings, house for sale, mls canada, homes for rent, mls map, mls real estate, homes for sale, condos for sale, homes for sale near me, townhouse for sale, property for sale, apartments for sale, property search, mls online, house prices, duplex for sale, real estate listings, real estate companies, cheap houses for sale, mls house listings, best real estate websites canada, single family homes for sale, mls residential map, detached house for sale, home listings, sold home prices, property listings, sold mls listings, house sale prices',
    noIndex: false,
    canonicalUrl: null,
    placePostalCode: null,
    placeStreetAddress: null,
    placeLocality: null,
    placeRegion: null,
    ogSiteName: theme.ogSiteName,
    ogImage: theme.ogImage,
    ogImageAlt: theme.ogImageAlt,
    ogType: 'website',
    ogUrl: theme.schemaUrl,
    ...data,
  };

  const websiteSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: theme.name,
    description: properties.metaDescription,
    keywords: properties.metaKeywords,
    url: theme.schemaUrl,
  };

  const corporationSchema = {
    '@context': 'http://schema.org',
    '@type': 'Corporation',
    name: theme.name,
    description: properties.metaDescription,
    url: theme.schemaUrl,
    logo: theme.schemaLogo,
    location: {
      '@context': 'http://schema.org',
      '@type': 'PostalAddress',
      ...theme.schemaLocation,
    },
    foundingDate: theme.schemaFoundingDate,
    founders: theme.schemaFounders,
    telephone: theme.organization.phoneNumber(false),
    email: theme.organization.email(false),
    sameAs: [
      theme.organization.twitterUrl,
      theme.organization.facebookUrl,
      theme.organization.instagramUrl,
      theme.organization.linkedinUrl,
      themeName === ThemeNames.ZOOCASA && 'https://www.bbb.org/ca/on/toronto/profile/real-estate-services/zoocasa-realty-inc-0107-1380462',
    ],
  };

  const schema = [websiteSchema, corporationSchema, ...(properties.schema || [])];
  const urlPath = useRouter().asPath;
  
  // Prevent Safari from automatically zooming in on text fields during user input without disabling the user’s ability to pinch zoom.
  // See https://stackoverflow.com/a/57527009 for more info
  const isIOS = (): boolean =>
    typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const viewPortContent = isIOS()
    ? 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes'
    : 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes';

  return (
    <Head>
      {/* Title Tag */}
      <title>{properties.title}</title>

      {/* Meta Description Tag */}
      <meta name="description" content={properties.metaDescription} />

      {/* Meta Keywords Tag */}
      <meta name="keywords" content={properties.metaKeywords} />

      {/* Meta Viewport Tag */}
      <meta name="viewport" content={viewPortContent} />

      {/* Meta Safari Smart Banner Tag */}
      <meta name="apple-itunes-app" content={`app-id=301743811, app-argument=${websiteSchema.url}${urlPath}`} />

      {/* Zoocasa Custom Tags */}
      <meta property="zoocasa:version" content={packageJSON.version} />
      {/* <meta property="zoocasa:version-git-sha" content={gitSha} /> */}

      {/* SEO Tags */}
      {properties.canonicalUrl && <link rel="canonical" href={properties.canonicalUrl} />}
      {(properties.noIndex || configJSON.host === 'https://stage.zoocasa.com') && <meta name="robots" content="noindex" />}

      {/* Place Tags */}
      {properties.placePostalCode && <meta property="place:postal_code" content={properties.placePostalCode} />}
      {properties.placeStreetAddress && <meta property="place:street_address" content={properties.placeStreetAddress} />}
      {properties.placeLocality && <meta property="place:locality" content={properties.placeLocality} />}
      {properties.placeRegion && <meta property="place:region" content={properties.placeRegion} />}

      {/* Open Graph Tags */}
      {properties.ogSiteName && <meta property="og:site_name" content={properties.ogSiteName} />}
      {properties.ogImage && <meta property="og:image" content={properties.ogImage} />}
      {properties.ogImageAlt && <meta property="og:image:alt" content={properties.ogImageAlt} />}
      {properties.ogType && <meta property="og:type" content={properties.ogType} />}
      {properties.ogUrl && <meta property="og:url" content={properties.ogUrl} />}
      <meta property="og:title" content={properties.title} />
      <meta property="og:description" content={properties.metaDescription} />

      {/* Schema's */}
      {schema && <script {...jsonLdScriptProps<any>(schema)} />}

      {/* App Manifest */}
      {isZoocasaTenant && <link rel="manifest" href="/manifest.json" />}

      {/* Favicon based off active theme */}
      <link rel="icon" href={`/${theme?.faviconPath}`} />
    </Head>
  );
}
