export enum ModalOpeners {
    FILTER_MODAL_OPEN = 'filter-modal-open',
    RESET_PASSWORD_TOKEN = 'reset-password-token',
    EMAIL = 'email',
    USER_AGENT = 'user-agent',
    CONFIRMATION_TOKEN = 'confirmation-token',
    SIGNIN_EMAIL = 'sign-in-email',
    NOTIFICATION = 'notification',
    LISTING_ID = 'listing-id',
    WARNING_MESSAGE = 'warning-message',
  }

export const ModalOpenersList = [
  ModalOpeners.FILTER_MODAL_OPEN,
  ModalOpeners.RESET_PASSWORD_TOKEN,
  ModalOpeners.EMAIL,
  ModalOpeners.USER_AGENT,
  ModalOpeners.CONFIRMATION_TOKEN,
  ModalOpeners.SIGNIN_EMAIL,
  ModalOpeners.NOTIFICATION,
  ModalOpeners.LISTING_ID,
  ModalOpeners.WARNING_MESSAGE,
];
