import getRequestHeader from 'utils/request-headers';
import { X_ZOOCASA_FEATURES_HEADER_NAME } from 'constants/headers';

import type { NextPageContext } from 'next';
import type { IncomingMessage } from 'http';

export default function getFeaturesOverrideFromContext(context: NextPageContext) {
  return getFeaturesOverrideFromRequest(context?.req);
}

export function getFeaturesOverrideFromRequest(req?: IncomingMessage) {
  const features = getRequestHeader(X_ZOOCASA_FEATURES_HEADER_NAME, req) || {};
  return features;
}