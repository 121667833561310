import newGeoJsonPolygon from '../geo-json-polygon';

export default function geojsonPolygonFromGoogleBbox(bbox: string) {
  if (isGoogleBbox(bbox)) {
    const [bottom, right, top, left] = bbox.split(',').map(parseFloat);
    return newGeoJsonPolygon(left, right, bottom, top);
  }
  return null;
}

function isGoogleBbox(bbox: string) {
  return bbox &&
    typeof bbox.match !== 'undefined' &&
    bbox.match(/^(-?\d+(\.\d+)?,){3}-?\d+(\.\d+)?$/);
}
