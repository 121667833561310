import type { GeoJsonPolygon } from './types';

export default function newGeoJsonPolygon(left: number, right: number, bottom: number, top: number): GeoJsonPolygon {
  return {
    type: 'Polygon',
    coordinates: [
      [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
        [left, bottom],
      ],
    ],
  };
}
