import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  isExpTheme?: boolean;
}

const FacebookIcon = ({ className, onClick, isExpTheme }: Props) => {
  return (isExpTheme
    ?
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none" className={className} onClick={onClick}>
      <path id="Vector" d="M9.66602 0.261719H7.21147C6.12649 0.261719 5.08595 0.709485 4.31876 1.50651C3.55157 2.30354 3.12056 3.38455 3.12056 4.51172V7.06172H0.666016V10.4617H3.12056V17.2617H6.39329V10.4617H8.84783L9.66602 7.06172H6.39329V4.51172C6.39329 4.28628 6.47949 4.07008 6.63293 3.91068C6.78637 3.75127 6.99447 3.66172 7.21147 3.66172H9.66602V0.261719Z" fill="white"/>
    </svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" className={className} onClick={onClick}>
      <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256s114.6 256 256 256c1.5 0 3 0 4.5-.1V312.7h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5C433.7 471.4 512 372.9 512 256z"></path>
    </svg>
  );
};

export default FacebookIcon;
