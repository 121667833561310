import Cookies from 'js-cookie';
import defaultListingParams, { DEFAULT_LISTING_PARAMS_US } from 'contexts/preferences/listing-params/defaults';
import { getJSON, getObjectFromRequestCookie } from 'utils/cookies';
import { LISTING_PARAMS_COOKIE_NAME } from 'constants/cookies';
import { getSiteLocationFromRequest } from 'utils/site-location';

import type { IncomingMessage } from 'http';
import type { ListingParams } from 'contexts/preferences/listing-params/types';

export const storageKey = LISTING_PARAMS_COOKIE_NAME;

export function extractListingParamsData(req?: IncomingMessage): ListingParams {
  const siteLocation = getSiteLocationFromRequest(req);
  const listingParams = siteLocation === 'US' ? DEFAULT_LISTING_PARAMS_US : defaultListingParams;
  return getObjectFromRequestCookie<ListingParams>(storageKey, req) || getJSON(Cookies.get(storageKey)) || listingParams;
}
