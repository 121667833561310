export default function lengthConverter(value: number, currentUnits: string, desiredUnits: string) {
  if (currentUnits === desiredUnits) {
    return value;
  } else {
    const scaleFactor = 0.3048;
    if (desiredUnits === 'feet') {
      return (value / scaleFactor).toFixed(2);
    } else {
      return (value * scaleFactor).toFixed(2);
    }
  }
}
