import { CountryCode, CountryCodeList } from 'types/countries';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { pluralize } from '@zoocasa/node-kit/strings';

export function translate(word: string, country?: CountryCode) {
  const isUpperCase = /^[A-Z]/.test(word);
  const isPlural = word.endsWith('s');
  const unformattedWord = unformat(word, isPlural);
  if (country === CountryCodeList.UNITED_STATES) {
    switch (unformattedWord) {
    case 'neighbourhood':
      return format('neighborhood', isUpperCase, isPlural);
    case 'maintenance fee':
      return 'HOA Fees';
    case 'favourite':
      return format('favorite', isUpperCase, isPlural);
    case 'favourited':
      return format('favorited', isUpperCase, isPlural);
    case 'my favourite':
      return format('my favorite', isUpperCase, isPlural);
    default:
      return word;
    }
  }
  return word;
}

function unformat(word: string, isPlural: boolean) {
  let unformattedWord = word.toLowerCase();
  if (isPlural) unformattedWord = unformattedWord.slice(0, unformattedWord.length - 1);

  return unformattedWord;
}

function format(translation: string, isUpperCase: boolean, isPlural: boolean) {
  let formattedTranslation = translation;
  if (isUpperCase) formattedTranslation = capitalizeWords(formattedTranslation);
  if (isPlural) formattedTranslation = pluralize(formattedTranslation);

  return formattedTranslation;
}
