import MapDefaultOptions from './default-options';
import geojsonPolygonFromGoogleBbox from './geojson-polygon-from-google-bbox';
import googleBoundaryFromGeojsonPolygon from './google-boundary-from-geojson-polygon';
import mappableOverlay from './mappable-overlay';
import { isMobileSearchView } from '../../browser';
import calculatePoint from '../../box-positioner/calculate-point';

import type { Position, PinPos, PopupPosition, Pin, GeoJsonPolygon, Frame, Offsets } from '../types';
import type { DrawMapOptions } from '../apple/types';
import type { Point } from '../../box-positioner/types';

const DEFAULT_ZOOM = 13;

export default class GoogleMap {
  mapElementId: string;
  map: google.maps.Map;
  googleEventListeners: any;
  markers: google.maps.Marker[];
  directionsDisplay: google.maps.DirectionsRenderer;

  drawMap(options: DrawMapOptions, mapElement: string) {
    this.mapElementId = mapElement;
    this.map = new google.maps.Map(this.mapElement);
    this.map.setOptions(options);
    mappableOverlay.defineMappableOverlay();
  }

  options(position: Position, zoom: number) {
    const options = this.adjustOptionsForMobile(MapDefaultOptions);
    // If the map is already drawn, use it's center, otherwise use the passed in values
    options.center = this.map ? this.map.getCenter() : position;
    // Set the zoom and determine the map/satelite view based on it
    options.zoom = zoom;
    options.mapTypeId = options.zoom <= 18 ? google.maps.MapTypeId.ROADMAP : google.maps.MapTypeId.HYBRID;
    return options;
  }

  onZoom(zoomListener: () => void) {
    this.addGoogleEventListener(this.map, 'zoom_changed', zoomListener);
  }

  onPan(panListener: () => void) {
    this.addGoogleEventListener(this.map, 'idle', panListener);
  }

  createPin(latitude: number, longitude: number, type = 'pin'): Pin {
    return {
      latitude,
      longitude,
      pinElement: document.createElement(type),
    };
  }

  addPin(mappable: any, showHover: () => void, removeHover: () => void): void {
    const overlay = new (mappableOverlay as any).mappableOverlayClass(this.map, mappable);
    mappableOverlay.addMappableOverlayClickHandler(overlay, showHover, removeHover);
    mappable.set('overlay', overlay);
  }

  removePins(mappable: any): void {
    if (mappable.overlay) {
      mappableOverlay.removeMappableOverlay(mappable.overlay);
      mappable.set('overlay', null);
    }
  }

  boundaryFromGeoJsonPolygon(boundary: GeoJsonPolygon): google.maps.LatLng[][] {
    return googleBoundaryFromGeojsonPolygon(boundary);
  }

  removeOverlay(hoverBoundaryMapPolygon: google.maps.Polygon): void {
    hoverBoundaryMapPolygon.setMap(null);
  }

  makePolygon(paths: google.maps.LatLng[]): google.maps.Polygon {
    return new google.maps.Polygon({
      paths: paths,
      map: this.map,
      strokeColor: '#5A5C65',
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: '#5A5C65',
      fillOpacity: 0.2,
    });
  }

  zoomIn(): void {
    this.map.setZoom(this.zoom + 1);
  }

  zoomOut(): void {
    this.map.setZoom(this.zoom - 1);
  }

  addGoogleEventListener(map: google.maps.Map, eventName: string, handler: (...args: any) => void): void {
    const listener = google.maps.event.addListener(map, eventName, handler);
    this.googleEventListeners.addObject(listener);
  }

  removeEventListeners(): void {
    this.googleEventListeners.forEach(function(listener: google.maps.MapsEventListener) {
      google.maps.event.removeListener(listener);
    });
  }

  changeLocation(location: Position, zoom: number): void {
    this.map.panTo(location);
    this.map.setZoom(zoom);
  }

  drawBoundaryPolygons(activeBoundariesMapPolygons: google.maps.OverlayView[], activeBoundariesGeojsonPolygons: GeoJsonPolygon[]): google.maps.Polygon[] {
    activeBoundariesMapPolygons.forEach(polygon => polygon.setMap(null));

    // Display new boundary polygons on map
    const polygons = activeBoundariesGeojsonPolygons.map(geojsonPolygon => {
      const options = {
        paths: googleBoundaryFromGeojsonPolygon(geojsonPolygon),
        map: this.map,
        strokeWeight: 0,
        fillColor: '#043C7E',
        fillOpacity: 0.2,
      };
      return new google.maps.Polygon(options);
    });
    return polygons;
  }

  updatePinPositionInPixels(): void {
    return;
  }

  calculateTarget(pinPos: PinPos, frame: Frame | null, _: unknown, yOffset: number): PopupPosition {
    // calculate target is broken for google maps, need to reimplement frame
    return {
      x: pinPos.x + (frame as any).width / 2,
      y: pinPos.y + (frame as any).height / 2 - yOffset,
      size: 20,
    };
  }

  placeImageMarker(icon: string, position: Position): void {
    const marker = this.imageMarker(icon, position);
    this.markers.push(marker);
    marker.setMap(this.map);
  }

  imageMarker(icon: string, position: Position): google.maps.Marker {
    const options = {
      position: position,
      icon: icon,
    };
    return new google.maps.Marker(options);
  }

  directions(origin: string, destination: string, travelMode: string) {
    const directionsService = new google.maps.DirectionsService();
    const options = {
      origin: origin,
      destination: destination,
      travelMode: travelMode.toUpperCase() as google.maps.TravelMode,
    };
    return new Promise((resolve, reject) => {
      directionsService.route(options, (res, status) => {
        if (status == 'OK') {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  travelDuration(directions: google.maps.DirectionsResult): string {
    return directions.routes[0].legs[0].duration?.text || '';
  }

  travelDistance(directions: google.maps.DirectionsResult): string {
    return directions.routes[0].legs[0].distance?.text || '';
  }

  drawOverlays(directions: google.maps.DirectionsResult): void {
    if (!this.directionsDisplay) {
      this.directionsDisplay = new google.maps.DirectionsRenderer();
    }
    this.directionsDisplay.setMap(null);
    this.directionsDisplay.setMap(this.map);
    this.directionsDisplay.setDirections(directions);
  }

  clearMarkers(): void {
    this.markers.forEach((marker: google.maps.Marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  getHoverPosition(latitude: number, longitude: number, popup: HTMLElement, offsets: Offsets): Point {
    const {
      yOffset = 15,
      framePadding = 15,
      frameOffset = 15,
      targetPadding = 50,
    } = offsets;
    const position = {
      x: latitude,
      y: longitude,
    };

    const target = this.calculateTarget(position, null, null, yOffset);
    const frame = {
      width: this.mapElement.offsetWidth,
      height: this.mapElement.offsetHeight,
      padding: framePadding,
    };
    const element = {
      width: popup.offsetWidth,
      height: popup.offsetHeight,
    };

    return calculatePoint({
      frame,
      element,
      target,
      frameOffset,
      targetPadding,
    });
  }

  get travelTabs() {
    return [
      { label: 'driving', icon: 'icon-cab' },
      { label: 'walking', icon: 'icon-walk' },
      { label: 'transit', icon: 'icon-bus' },
    ];
  }

  get travelData() {
    return {
      driving: null,
      walking: null,
      transit: null,
    };
  }

  get mapElement() {
    const element = document.getElementById(this.mapElementId);
    if (!element) {
      const div = document.createElement('div');
      div.id = this.mapElementId;
      return div;
    }
    return element;
  }

  get boundary(): GeoJsonPolygon | null {
    return geojsonPolygonFromGoogleBbox(this.bboxFromBounds);
  }

  get latitude() {
    return this.map.getCenter()?.lat() || 0;
  }

  get longitude() {
    return this.map.getCenter()?.lng() || 0;
  }

  get zoom() {
    return this.map.getZoom() || DEFAULT_ZOOM;
  }

  get annotations() {
    return null;
  }

  // ----- Private Functions -----
  adjustOptionsForMobile(options: any) {
    if (isMobileSearchView()) {
      options.zoomControl = false;
      options.streetViewControl = false;
      options.rotateControl = false;
    }
    return options;
  }

  get bboxFromBounds() {
    const bounds = this.map.getBounds();
    if (bounds) {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      return `${sw.lat()},${ne.lng()},${ne.lat()},${sw.lng()}`;
    }
    return '';
  }
}
