import React, { Ref, ReactElement, forwardRef } from 'react';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

import type { ButtonHTMLAttributes } from 'react';

//#region Constants
export const PRIMARY_THEME = 'primary';
export const SECONDARY_THEME = 'secondary';
export const TERTIARY_THEME = 'tertiary';
//#endregion

//#region Types
export type theme = typeof PRIMARY_THEME | typeof SECONDARY_THEME | typeof TERTIARY_THEME;

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  label?: string | ReactElement;
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  theme?: theme;
  Icon?: any;
  RightIcon?: any;
  rightIconClassName?: string;
  className?: string;
  testId?: string;
  modifyIcon?: boolean;
  expHomePadding?: boolean;
};
//#endregion

const Button = forwardRef(({ label, onClick, theme, Icon, RightIcon, rightIconClassName, className, testId, modifyIcon = true, expHomePadding = false, ...inputProps }: Props, ref: Ref<HTMLButtonElement>) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <button
      {...inputProps}
      className={buildClassName(styles.component, theme, styles[theme!], expHomePadding && styles['homepage-button-padding'], isExpTheme && styles['exp-theme'], className)}
      onClick={onClick}
      ref={ref}
      data-testid={testId}
    >
      {Icon && <Icon className={buildClassName(styles['left-icon'], modifyIcon && styles.modify)} />}
      {label}
      {RightIcon && <RightIcon className={buildClassName(styles['right-icon'], rightIconClassName)} />}
    </button>
  );
});

export default Button;