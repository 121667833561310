import { ThemeNames } from 'types/themes';
import { themes, ThemeName } from 'themes';
import configJson from 'config.json';
import { X_ZOOCASA_FEATURES_HEADER_NAME } from 'constants/headers';
import { CountryCodeList } from 'types/countries';
import { CountryCode } from 'types/countries';

import type { IncomingMessage } from 'http';
import type { Themes } from 'types/themes';

export function getThemeOverrideFromRequest(req?: Pick<IncomingMessage, 'headers'>): ThemeNames | null {
  let host = '';
  if (req == null) {
    return null;
  }
  if (req.headers.host) {
    host = req.headers.host;
  }

  // if someone is using a featureflag to overwrite the theme, let them do that
  if (req.headers[X_ZOOCASA_FEATURES_HEADER_NAME]?.includes('useExpRealtyCA=true')) return ThemeNames.EXP_REALTY_CA;
  if (req.headers[X_ZOOCASA_FEATURES_HEADER_NAME]?.includes('useExpRealtyUS=true')) return ThemeNames.EXP_REALTY_US;
  if (req.headers[X_ZOOCASA_FEATURES_HEADER_NAME]?.includes('useExpCommercial=true')) return ThemeNames.EXP_COMMERCIAL;

  // if someone is trying to access the site from 'zoocasa.com' or 'exprealty.ca'
  switch (host) {
  case 'exprealty.ca':
  case 'zooexptest.ca':
  case 'zooexpprod.ca':
  case 'www.zooexpprod.ca':
  case 'www.exprealty.ca':
    return ThemeNames.EXP_REALTY_CA;
  case 'zooexptest.com':
  case 'www.zooexptest.com':
  case 'exprealty.com':
  case 'www.exprealty.com':
    return ThemeNames.EXP_REALTY_US;
  case 'zooexpprod.com':
  case 'www.zooexpprod.com':
  case 'expcommercial.com':
  case 'www.expcommercial.com':
    return ThemeNames.EXP_COMMERCIAL;
  default:
    return ThemeNames.ZOOCASA;
  }
}

export const themeNameOrDefault = (themeName?: string | null): ThemeName => {
  if (themeName != null && Object.keys(themes).includes(themeName)) {
    return themeName as ThemeName;
  }

  return ThemeNames.ZOOCASA;
};

export const themeOrDefault = (themeName?: string | null): Themes => {
  if (themeName != null && Object.keys(themes).includes(themeName)) {
    return themeName as Themes;
  }

  return ThemeNames.ZOOCASA;
};

export function getGTMContainerIdFromTheme(themeName?: Themes) {
  switch (themeName) {
  case ThemeNames.ZOOCASA:
    return configJson.googleTagManager.containerId;
  case ThemeNames.EXP_REALTY_CA:
    return configJson.googleTagManager.containerIdExpCa;
  case ThemeNames.EXP_REALTY_US:
    return configJson.googleTagManager.containerIdExpUs;
  default:
    return configJson.googleTagManager.containerId;
  }
}

export function getGoogleSSOClientIdFromTheme(themeName: ThemeName) {
  switch (themeName) {
  case ThemeNames.ZOOCASA:
    return process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID || '';
  case ThemeNames.EXP_REALTY_CA:
  case ThemeNames.EXP_REALTY_US:
    return process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID_EXP || '';
  default:
    return process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID || '';
  }
}

export function getFacebookSSOAppIdFromTheme(themeName: ThemeName) {
  switch (themeName) {
  case ThemeNames.ZOOCASA:
    return process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '';
  case ThemeNames.EXP_REALTY_CA:
  case ThemeNames.EXP_REALTY_US:
    return process.env.NEXT_PUBLIC_FACEBOOK_APP_ID_EXP || '';
  default:
    return process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '';
  }
}

export function getContentPageHost(themeName?: Themes) {
  switch (themeName) {
  case ThemeNames.ZOOCASA:
    return 'https://content.zoocasa.com';
  case ThemeNames.EXP_REALTY_CA:
    return 'https://exp-ca.content.zoocasa.com';
  case ThemeNames.EXP_REALTY_US:
    return 'https://exp-us.content.zoocasa.com';
  default:
    return 'https://content.zoocasa.com';
  }
}

export function getSitemapHostFromTheme(themeName: Themes | null) {
  switch (themeName) {
  case ThemeNames.ZOOCASA:
    return 'https://www.zoocasa.com';
  case ThemeNames.EXP_REALTY_CA:
    return 'https://www.exprealty.ca';
  case ThemeNames.EXP_REALTY_US:
    return 'https://www.exprealty.com';
  default:
    return 'https://www.zoocasa.com';
  }
}

export const getExpCountryCode = (tenantName: ThemeName): CountryCode | undefined => {
  let countryCode: CountryCode | undefined = undefined;
  if (tenantName === ThemeNames.EXP_REALTY_CA) {
    countryCode = CountryCodeList.CANADA;
  }
  if (tenantName === ThemeNames.EXP_REALTY_US) {
    countryCode = CountryCodeList.UNITED_STATES;
  }
  if (tenantName === ThemeNames.EXP_COMMERCIAL) {
    countryCode = CountryCodeList.UNITED_STATES;
  }
  return countryCode;
};