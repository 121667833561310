export default function updateQueryParams(params: Record<string, unknown>, historyType: 'replace' | 'push' = 'replace') {
  const { pathname, search } = window.location;
  const newSearch = pathname + updateSearchParams(search, params);

  if (historyType === 'push') {
    window.history.pushState({ ...window.history.state, as: newSearch, url: newSearch }, '', newSearch);
  } else {
    window.history.replaceState({ ...window.history.state, as: newSearch, url: newSearch }, '', newSearch);
  }
}

export function updateSearchParams(searchUrl: string, params: Record<string, unknown>) {
  let newSearchUrl = searchUrl;
  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value) {
      newSearchUrl = addSearchParam(newSearchUrl, key, `${value}`);
    } else {
      newSearchUrl = removeSearchParam(newSearchUrl, key);
    }
  });
  return newSearchUrl;
}

// Source: https://stackoverflow.com/a/6021027/7286724
export function addSearchParam(url: string, key: string, value: string) {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';
  if (url.match(re)) {
    return url.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return url + separator + key + '=' + value;
  }
}

// Source: https://stackoverflow.com/a/1634841/7286724
export function removeSearchParam(url: string, key: string) {
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(key) + '=';
    const pars = urlparts[1].split(/[&;]/g);
    for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

// Source: https://stackoverflow.com/a/47444595
export function getQueryParams(url: string) {
  return url
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj: Record<string, unknown>, pair) => {
      const [key, value] = pair.map(decodeURIComponent);
      obj[key] = value;
      return obj;
    }, {});
}

export function setQueryParams(newUrl: string) {
  const { pathname } = window.location;
  newUrl = pathname + '?' + newUrl;
  window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
}

// test-private-code
export const testExports = { updateSearchParams, addSearchParam, removeSearchParam };
// end-test-private-code
