import type { SchoolParams } from 'contexts/preferences/school/types';

export default {
  showSchools: false,
  filterListings: false,
  showUnrated: false,
  ratingType: 'eqao',
  eqaoRating: 0,
  levelFilter: 'all',
  typeFilter: 'all',
  languageFilter: 'all',
} as SchoolParams;
