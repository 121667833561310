import calculatePosition from './calculate-position';

import type { CalculationOptions, Point } from './types';

export default function calculatePoint(options: CalculationOptions): Point {
  const { point: { x, y }, direction } = calculatePosition(options);
  const { width, height } = options.element;
  return {
    x: direction.pointsLeft ? x - width : x,
    y: direction.pointsUp ? y - height : y,
  };
}
