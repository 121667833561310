import type { SelectOption } from '@zoocasa/node-kit/components/controls/types';
import type { Sort, Status, Bedroom, Locker, PropertyType } from 'contexts/preferences/listing-params/types';
import type { SchoolLevelFilter, SchoolLanguageFilter, SchoolTypeFilter } from 'contexts/preferences/school/types';
import type { SearchOptions } from 'themes/themeConfig';
export type NotificationFrequency = 0 | -1 | 86400 | 604800;
export type BuySellOptions = 'buy' | 'sell' | 'both';

export const removeSpacesInLabel = (label: string) => {
  return label.replace(/\s/g, '');
};

export const notificationFrequencyOptions: SelectOption<NotificationFrequency>[] = [
  { value: 0, label: 'No alerts' },
  { value: -1, label: 'Instant' },
  { value: 86400, label: 'Daily' }, // 86,400s
  { value: 604800, label: 'Weekly' }, // 604,800s
];
export const NOTIFICATION_FREQUENCY_DEFAULT_OPTION = 86400;

export const inActiveNotificationFrequencyOptions: SelectOption<NotificationFrequency>[] = [
  { value: 0, label: 'No alerts available for Sold or Inactive listings' },
];

export function searchOptions(options: { type: SearchOptions }[]): SelectOption<SearchOptions>[] {
  const allOptions: SelectOption<SearchOptions>[] = [
    { value: 'Buy', label: 'Buy' },
    { value: 'Rent', label: 'Rent' },
    { value: 'Sold', label: 'Sold' },
    { value: 'Agents', label: 'Agents' },
  ];

  return options.map(option => {
    const matchingOption = allOptions.find(o => o.label === option.type);
    return matchingOption || null;
  }).filter(Boolean) as SelectOption<SearchOptions>[];
}

export const listingTypeSelectionOptions: SelectOption<boolean>[] = [
  { value: false, label: 'Buy Listings' },
  { value: true, label: 'Rent Listings' },
];

export function sortOptions(status: Status): SelectOption<Sort>[] {
  let options: SelectOption<Sort>[];
  if (status === 'available') {
    options = [
      { value: 'price', label:'Price (Low to High)' },
      { value: '-price', label:'Price (High to Low)' },
      { value: '-date', label:'Newest to Oldest' },
      { value: 'date', label:'Oldest to Newest' },
    ];
  } else if (status === 'not-available-sold') {
    options = [
      { value: '-date', label:'Sold Date (New to Old)' },
      { value: 'date', label:'Sold Date (Old to New)' },
      { value: 'price', label:'Sold Price (Low to High)' },
      { value: '-price', label:'Sold Price (High to Low)' },
    ];
  } else {
    options = [
      { value: '-date', label:'Removed Date (New to Old)' },
      { value: 'date', label:'Removed Date (Old to New)' },
      { value: 'price', label:'Price (Low to High)' },
      { value: '-price', label:'Price (High to Low)' },
    ];
  }
  options.push(
    { value: '-bedrooms', label:'Bedrooms (High to Low)' },
    { value: 'bedrooms', label:'Bedrooms (Low to High)' },
    { value: '-bathrooms', label:'Bathrooms (High to Low)' },
    { value: 'bathrooms', label:'Bathrooms (Low to High)' }
  );
  return options;
}

export function statusOptions(isRental = false, addsSuffix = false): SelectOption<Status>[] {
  const suffix = addsSuffix ? ' Listings' : '';
  return [
    { value: 'available', label: 'Active' + suffix },
    { value: 'not-available-sold', label: (isRental ? 'Leased' : 'Sold') + suffix },
    { value: 'not-available-other', label: 'Expired' + suffix },
  ];
}

export type PropertyTypeOption = SelectOption<PropertyType> & { options?: SelectOption<PropertyType>[] };

export const legacyPropertyTypeOptions: PropertyTypeOption[] = [
  { value: 'house', label: 'House' },
  { value: 'townhouse', label: 'Townhouse' },
  { value: 'condo', label: 'Condo' },
];

export const propertyTypeOptions: PropertyTypeOption[] = [
  { value: 'house', label: 'House' },
  { value: 'townhouse', label: 'Townhouse' },
  { value: 'condo', label: 'Condo' },
  { value: 'land', label: 'Land' },
  { value: 'commercial', label: 'Commercial' },
  { value: 'farm', label: 'Farm' },
];

export const propertyTypeFlatOptions = [
  { value: 'house', label: 'House' },
  { value: 'townhouse', label: 'Townhouse' },
  { value: 'condo', label: 'Condo' },
  { value: 'farm', label: 'Farm' },
  { value: 'land', label: 'Land' },
  { value: 'commercial', label: 'Commercial' },
];

export const homeTypeAppraisalOptions = [
  { value: '', label: 'Select...' },
  { value: 'houseDetached', label: 'House' },
  { value: 'townhouse', label: 'Townhouse' },
  { value: 'condo', label: 'Condo' },
];

export function bedroomBathroomParkingButtonGroupOptions(isExactNumber: boolean) {
  const isExactNumberData = [
    { value: '0+', label: 'Any' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ];
  const isNotExactNumberData = [
    { value: '0+', label: 'Any' },
    { value: '1+', label: '1+' },
    { value: '2+', label: '2+' },
    { value: '3+', label: '3+' },
    { value: '4+', label: '4+' },
    { value: '5+', label: '5+' },
  ];
  return isExactNumber ? isExactNumberData : isNotExactNumberData;
}

export const bedroomSelectionOptions: SelectOption<Bedroom>[] = [
  { value: 0, label: '0 Beds' },
  { value: 1, label: '1 Bed' },
  { value: 2, label: '2 Beds' },
  { value: 3, label: '3 Beds' },
  { value: 4, label: '4 Beds' },
  { value: 5, label: '5 Beds' },
];

export const lockerSelectionOptions: SelectOption<Locker>[] = [
  { value: 'any', label: 'Any' },
  { value: 'yes', label: 'With lockers' },
  { value: 'no', label: 'No lockers' },
];

export const schoolLevelOptions: SelectOption<SchoolLevelFilter>[] = [
  { value: 'all', label: 'All' },
  { value: 'elementary', label: 'Elementary' },
  { value: 'secondary', label: 'Secondary' },
];

export const schoolTypeOptions: SelectOption<SchoolTypeFilter>[] = [
  { value: 'all', label: 'All' },
  { value: 'public', label: 'Public' },
  { value: 'catholic', label: 'Catholic' },
];

export const schoolLanguageOptions: SelectOption<SchoolLanguageFilter>[] = [
  { value: 'all', label: 'All' },
  { value: 'english', label: 'English' },
  { value: 'french', label: 'French' },
];

export const connectWithAgentOptions: SelectOption<BuySellOptions>[] = [
  { value: 'buy', label: 'Buy' },
  { value: 'sell', label: 'Sell' },
  { value: 'both', label: 'Buy & Sell' },
];

export const defaultRentPriceOptions = [500, 1000, 1200, 1400, 1600, 1800, 2000, 2500, 3000];
export const defaultBuyPriceOptions = [50000, 100000, 200000, 400000, 600000, 800000, 1000000, 1250000, 1500000];
export const defaultSqftOptions = [1000, 1500, 2000, 2500, 3000, 3500];
export const defaultMaintenanceOptions = [50, 100, 150, 200, 250, 300];
