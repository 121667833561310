import isGeojsonPoint from '../utils/is-geojson-point';

import type { GeoJsonPoint } from '../types';

export default function mapkitPositionFromGeojsonPoint(geojsonPosition?: GeoJsonPoint) {
  if (geojsonPosition && isGeojsonPoint(geojsonPosition)) {
    const [lng, lat] = geojsonPosition.coordinates;
    return new mapkit.Coordinate(lat, lng);
  }
}
