const options: any = {
  // Enable/Disable Controls
  zoomControl: false,
  rotateControl: true,
  streetViewControl: true,
  mapTypeControl: false,
  scaleControl: false,
  scrollwheel: false,
  // draggable: false, // Disable pinch to zoom
  clickableIcons: false,

  // Configure Controls
  zoomControlOptions: {
    position: null,
  },
  streetViewControlOptions: {
    position: null,
  },
  rotateControlOptions: {
    position: null,
  },

  // Hide Points of Interest
  // Source: http://gmaps-samples-v3.googlecode.com/svn/trunk/styledmaps/wizard/index.html
  styles: [
    {
      featureType: 'poi.school',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],

  custom: {
    zoomControlPosition: null,
    schoolControlPosition: null,
  },
};

if (typeof google !== 'undefined') {
  options.zoomControlOptions.position = google.maps.ControlPosition.TOP_LEFT;
  options.streetViewControlOptions.position = google.maps.ControlPosition.TOP_RIGHT;
  options.rotateControlOptions.position = google.maps.ControlPosition.RIGHT_TOP;
}

export default options;
