import formatNumber from './format-number';

export default function formatCurrency(value: number) {
  const number = formatNumber(value);
  if (number) {
    return `$${number}`;
  }
}

function removeCommasFromPrice(price: string | undefined) {
  return price?.replace(/,/g, '');
}

export { removeCommasFromPrice };
