import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { loadMapKit } from 'utils/apple-maps';
import getMap from '@zoocasa/node-kit/map/get-map';

import type { Map } from '@zoocasa/node-kit/map/get-map';

export interface IMapContext {
  map: Map;
  hasDrawnMap: boolean;
  setHasDrawnMap: (isDrawn: boolean) => void;
  currentPins: any[];
  setCurrentPins: (pins: any[]) => void;
  highlightPin: (id: number) => void;
  unhighlightPin: () => void;
}

interface Pin {
  latitude: number;
  longitude: number;
  listingIds: number[];
  pinAnnotation: any;
  pinElement: any;
  removeClickListener: () => void;
}

export const MapContext = createContext<IMapContext | Record<string, unknown>>({});

export function useMapContext <T extends IMapContext | Record<string, unknown> = IMapContext>() {
  return useContext(MapContext) as T;
}

interface Props {
  children: ReactNode;
}

export default function MapContextProvider({ children }: Props) {
  const [map, setMap] = useState<Map | null>(null);
  const [hasDrawnMap, setHasDrawnMap] = useState(false);
  const [currentPins, setCurrentPins] = useState([]);
  const [highlightedPin, setHighlightedPin] = useState<Pin | null>(null);
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.startsWith('/search') && !map) {
      setTimeout(() => loadMapKit().then(() => setMap(getMap('apple'))));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const highlightPin = (id: number) => {
    currentPins.forEach((pin: any) => {
      if (pin.listingIds.includes(id)) {
        const pinElement = pin.pinElement.children[0];
        if (pinElement) {
          pinElement.style.backgroundColor = '#46484a';
        }
        // Control colour of bottom nib arrow
        if (pinElement?.children[0]) {
          pinElement.children[0].style.borderTop = '7px solid #46484a';
        }
        setHighlightedPin(pin);
      }
    });
  };

  const unhighlightPin = () => {
    if (highlightedPin) {
      const pinElement = highlightedPin.pinElement.children[0];
      pinElement.style.backgroundColor = '#df6541';
      if (pinElement.children[0]) {
        pinElement.children[0].style.borderTop = '7px solid #df6541';
      }
    }
  };

  return (
    <MapContext.Provider value={{
      map,
      hasDrawnMap,
      setHasDrawnMap,
      currentPins,
      setCurrentPins,
      highlightPin,
      unhighlightPin,
    }}>
      {children}
    </MapContext.Provider>
  );
}
