import React, { useEffect, useState } from 'react';
import ZoocasaIcon from 'components/icon/zoocasa-icon';
import NextImage from 'next/image';
import { useUserContext } from 'contexts';
import { CountryCodeList } from 'types/countries';
import { useIsMobile } from 'hooks/use-size-class';
import Cookies from 'js-cookie';
import styles from './style.module.scss';
import { trackEvent } from 'utils/google-tag-manager';

export default function LocationPickerScreen() {
  const { siteLocation, userLocation, handleSetSiteLocation, handleSetDefaultUserLocation } = useUserContext();
  const isMobile = useIsMobile();
  const [isHidden, setIsHidden] = useState(!!siteLocation);
  const flagDimensions = isMobile ? 100 : 200;

  const setCanadaLocation = () => {
    handleSetSiteLocation(CountryCodeList.CANADA);
    !userLocation && handleSetDefaultUserLocation(CountryCodeList.CANADA);
    setIsHidden(true);

    userLocation?.countryCode === CountryCodeList.UNITED_STATES && trackEvent('UICountryUSAFalse');
  };
  const setUSALocation = () => {
    handleSetSiteLocation(CountryCodeList.UNITED_STATES);
    !userLocation && handleSetDefaultUserLocation(CountryCodeList.UNITED_STATES);
    setIsHidden(true);

    userLocation?.countryCode === CountryCodeList.UNITED_STATES && trackEvent('UICountryUSATrue');
  };

  useEffect(() => {
    Cookies.set('loc-picker', 'true');
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <div className={styles.component}>
      <div className={`${styles['brush-stroke']} ${styles['brush-stroke-1']}`}>
        <NextImage
          key="b-1"
          layout="fill"
          src="/next/assets/images/brush-stroke-1.png" 
          alt="Zoocasa brush stroke"
          priority={true}
        />
      </div>
      <div className={`${styles['brush-stroke']} ${styles['brush-stroke-2']}`}>
        <NextImage
          key="b-1"
          layout="fill"
          src="/next/assets/images/brush-stroke-2.png" 
          alt="Zoocasa brush stroke"
          priority={true}
        />
      </div>
      <div className={styles.wrapper}>
        <ZoocasaIcon />
        <div className={styles.content}>
          <h2>Hello! To serve you better, which country do you currently live in?</h2>
          <p className={styles.disclaimer}>You can view listings from all locations no matter where you currently live</p>
          <div className={styles.choices}>
            <div className={styles.choice} onClick={setUSALocation}>
              <NextImage
                key="us-flag"
                src="/next/assets/images/flag-for-us-outlying-islands.png" 
                alt="United States flag"
                width={flagDimensions}
                height={flagDimensions}
              />
              <p>United States</p>
            </div>
            <div className={styles.choice} onClick={setCanadaLocation}>
              <NextImage
                key="ca-flag"
                src="/next/assets/images/flag-for-canada.png" 
                alt="Canadian flag"
                width={flagDimensions}
                height={flagDimensions}
              />
              <p>Canada</p>
            </div>
          </div>
          <p className={styles.outside} onClick={setCanadaLocation}>I do not currently live in Canada or the United States</p>
        </div>
      </div>
    </div>
  );
}
