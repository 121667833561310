import { useEffect, useRef } from 'react';

type DebouncedFunction = (...args: any[]) => void;

// Source: https://gist.github.com/mudge/eb9178a4b6d595ffde8f9cb31744afcf
export default function useDebounce(fn: DebouncedFunction, delay: number) {
  const latestCallback = useRef<DebouncedFunction>(fn);
  const latestTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    latestCallback.current = fn;
  }, [fn]);

  return () => {
    if (latestTimeout.current) {
      clearTimeout(latestTimeout.current);
    }

    latestTimeout.current = setTimeout(() => latestCallback.current(), delay);
  };
}
