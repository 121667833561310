import storage from 'utils/storage';
import Cookies from 'js-cookie';
import { USER_EXPIRE_DAYS } from 'constants/cookies';

export default function transferLocalStorageDataToCookie(name: string) {
  const localStorageData = storage.get(name);
  if (localStorageData) {
    if (!Cookies.get(name)) {
      Cookies.set(name, JSON.stringify(localStorageData), { expires: USER_EXPIRE_DAYS });
    }
    storage.delete(name);
  }
}
