import { useState } from 'react';
import Cookies from 'js-cookie';
import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';

export default function useCookieState<T extends string | Record<string, unknown>>(name: string, initialState: T) {
  const [state, setState] = useState<T | undefined>(initialState);

  const setCookieState = (state: T) => {
    if (state === undefined) {
      Cookies.remove(name);
    } else {
      Cookies.set(name, JSON.stringify(state), { expires: DEFAULT_EXPIRE_DAYS });
    }
    setState(state);
  };

  return [state, setCookieState];
}
