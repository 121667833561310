import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useAuthenticatedRoute(isAuthenticated: boolean) {
  const router = useRouter();
  const onProtectedPath = router.asPath.includes('/account');

  useEffect(() => {
    if (onProtectedPath && !isAuthenticated) {
      router.push('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onProtectedPath, isAuthenticated]);
}
