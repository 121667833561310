import React from 'react';

const NoImageIcon = () => {
  return (
    <svg width="136" height="85" viewBox="0 0 136 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.5 12V37.5L37 29.5V12H26.5Z" fill="#898989" stroke="black"/>
      <path d="M70 5.5L52.5 18.5L25.5 41V78.5H111.5V39.5L70 5.5Z" fill="#F3EFE8" stroke="black"/>
      <path d="M2.9726 60.4998C3.50018 64.9998 5.97259 68.6664 7.47259 69.9998H25.4726C24.9726 69.9998 27.4726 65.9998 28.4726 60.9998C29.4726 55.9998 26.5 43.5 25 41.5C23.8 39.9 20.8059 38.9998 19.9726 38.9998C19.3367 35.498 17.9452 32.9995 14.9726 32.9998C12 33 9.52741 37.5002 9 39.5C8.26442 42.2891 8.30592 45.4998 8.47259 47.4998C3.70203 51.4426 2.2657 54.4704 2.9726 60.4998Z" fill="#DBC7A7" stroke="black"/>
      <path d="M25.5 78.5V41.5L46.5 25L69 43V78.5H25.5Z" fill="#F3EFE8" stroke="black"/>
      <path d="M8 84.5H131" stroke="black"/>
      <path d="M9 81.5H19" stroke="black"/>
      <path d="M73.5 82.5H96" stroke="black"/>
      <path d="M112 81.5H122" stroke="black"/>
      <path d="M74.5 16H64.5V23.5H74.5V16Z" fill="white" stroke="black"/>
      <path d="M98.5 35H87.5V49H98.5V35Z" fill="white" stroke="black"/>
      <path d="M99.5 56.5H87.5V66.5H99.5V56.5Z" fill="#D8C19E" stroke="black"/>
      <path d="M73.5 56V79H83.5V56H73.5Z" fill="#3E4042" stroke="black"/>
      <path d="M38.5 11H25.5V13H38.5V11Z" fill="black" stroke="black"/>
      <path d="M51.5 35H42V43H51.5V35Z" fill="white" stroke="black"/>
      <path d="M62 52.5H32.5V70H62V52.5Z" fill="#D8C19E" stroke="black"/>
      <path d="M32.5 61.5H61.5" stroke="black"/>
      <path d="M41.5 52.5L41.5 70.5" stroke="black"/>
      <path d="M51 52.5V70" stroke="black"/>
      <path d="M104.5 77.5H20.5V80.5H104.5V77.5Z" fill="black" stroke="black"/>
      <path d="M3.5 70H25.5" stroke="black"/>
      <path d="M108.5 69.5H132.5" stroke="black"/>
      <path d="M3.5 74.5H25.5" stroke="black"/>
      <path d="M108.5 73.5H132.5" stroke="black"/>
      <path d="M3.5 79H25.5" stroke="black"/>
      <path d="M108.5 78.5H132.5" stroke="black"/>
      <path d="M3.5 81.25V68.25C3.5 67.5596 2.94036 67 2.25 67C1.55964 67 1 67.5596 1 68.25V81.25C1 81.9404 1.55964 82.5 2.25 82.5C2.94036 82.5 3.5 81.9404 3.5 81.25Z" fill="#947445" stroke="black"/>
      <path d="M135 80.75V67.75C135 67.0596 134.44 66.5 133.75 66.5C133.06 66.5 132.5 67.0596 132.5 67.75V80.75C132.5 81.4404 133.06 82 133.75 82C134.44 82 135 81.4404 135 80.75Z" fill="#947445" stroke="black"/>
      <path d="M46.5 22L17 44.5L18.5 46.5L46.5 25L73 46L74.5 44.5L46.5 22Z" fill="#947445" stroke="black"/>
      <path d="M69.5 1L45 20.5L75 44L77.5 41L77 39L52.5 18.5L69.5 5.5L118.5 45L121 42.5L69.5 1Z" fill="#333333" stroke="black"/>
      <path d="M13.5039 66C13.5031 67.5927 13.8044 68.4613 14.5039 70" stroke="black"/>
      <path d="M108 80.75V67.75C108 67.0596 107.44 66.5 106.75 66.5C106.06 66.5 105.5 67.0596 105.5 67.75V80.75C105.5 81.4404 106.06 82 106.75 82C107.44 82 108 81.4404 108 80.75Z" fill="#947445" stroke="black"/>
    </svg>
  );
};

export default NoImageIcon;
