import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const MailIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="21" viewBox="0 0 31 21" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M27.72 0H3C1.359 0 0 1.333 0 3v14.4c0 1.654 1.346 3 3 3h24.72c1.654 0 3-1.346 3-3V3c0-1.645-1.34-3-3-3zm-2.325 2.4L15.36 8.778 5.325 2.4h20.07zm2.925 15a.6.6 0 01-.6.6H3a.6.6 0 01-.6-.6V3.385l12.316 7.828a1.2 1.2 0 001.288 0L28.32 3.385V17.4z"></path>
        </g>
      </g>
    </svg>
  );
};

export default MailIcon;
