import { DEFAULT_EXPIRE_DAYS } from 'constants/cookies';
import Cookies from 'js-cookie';

const storage = {
  set(key: string, data: any) {
    const object = {
      data: data,
      timestamp: new Date().getTime(),
    };
    const objectString = JSON.stringify(object);
    if (this.localStorage.isActive()) {
      this.localStorage.set(key, objectString);
    } else {
      Cookies.set(key, objectString, { expires: DEFAULT_EXPIRE_DAYS });
    }
  },
  get(key: string) {
    const objectString = this.localStorage.isActive() ? this.localStorage.get(key) : Cookies.get(key);
    let object: Record<string, unknown> = {};
    if (objectString) {
      try {
        object = JSON.parse(objectString);
      } catch (error) {
        // continue regardless of error
      }
    }
    if (object && object.data && this.isFreshData(object.timestamp as number, key)) {
      return object.data;
    } else {
      this.delete(key);
    }
  },
  delete(key: string) {
    if (this.localStorage.isActive()) {
      this.localStorage.delete(key);
    } else {
      Cookies.remove(key);
    }
  },
  isFreshData(timestamp: number, dataType: string) {
    if (timestamp > this.globalCacheResetTimestamp) {
      const timeLimitInDays = dataType === 'user' ? this.timeLimitsInDaysByType[dataType] : 1; // Default freshness to 1 day
      const currentTime = new Date().getTime();
      const timeDifferenceInSeconds = (currentTime - timestamp) / 1000;
      const timeLimitInSeconds = timeLimitInDays * 24 * 60 * 60;
      return timeDifferenceInSeconds <= timeLimitInSeconds;
    }
  },
  globalCacheResetTimestamp: new Date(2016, 9, 17, 0, 0, 0).getTime(),
  timeLimitsInDaysByType: {
    user: 30, // Users can be logged in 30 days
  },
  localStorage: {
    isActive() {
      try {
        window.localStorage.setItem('test-localStorage', 'test');
        window.localStorage.removeItem('test-localStorage');
        return true;
      } catch (error) {
        return false;
      }
    },
    set(key: string, value: string) {
      window.localStorage.setItem(key, value);
    },
    get(key: string) {
      return window.localStorage.getItem(key);
    },
    delete(key: string) {
      window.localStorage.removeItem(key);
    },
  },
};

export default storage;
