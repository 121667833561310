import React, { createContext, ReactNode, useState } from 'react';

export interface IDropdownContext {
  isActive: boolean;
  closeDropdown: () => void;
  toggleDropdown: () => void;
}

export const DropdownContext = createContext<IDropdownContext | Record<string, unknown>>({});

interface Props {
  children: ReactNode;
}

export default function DropdownContextProvider({ children }: Props) {
  const [isActive, setIsActive] = useState<IDropdownContext['isActive']>(false);

  const closeDropdown: IDropdownContext['closeDropdown'] = () => setIsActive(false);
  const toggleDropdown: IDropdownContext['toggleDropdown'] = () => setIsActive(isActive => !isActive);

  return (
    <DropdownContext.Provider value={{ isActive, closeDropdown, toggleDropdown }}>
      {children}
    </DropdownContext.Provider>
  );
}
