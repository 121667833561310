import { HttpRequestMethodTypes, JSON_CONTENT_TYPE_HEADER } from 'types';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { FetchOptions } from 'types/fetchWithRetry';

import type { LastSearchRequestBodyType, GetUserLastSearchBodyType, GetUserLastSearchResponse } from './types';

export async function captureLastSearch(body: LastSearchRequestBodyType) {
  const options: FetchOptions = {
    headers: JSON_CONTENT_TYPE_HEADER,
    method: HttpRequestMethodTypes.POST,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetchWithRetry('/api/last-search/handler', options);
    const parsedResponse = await response.json();
    if (response.ok) {
      return parsedResponse;
    } else if ('error' in parsedResponse) {
      const { error } = parsedResponse;
      return new Error(error) || new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    return error;
  }
}

export async function getUserLastSearch(body: GetUserLastSearchBodyType) {
  const options: FetchOptions = {
    headers: JSON_CONTENT_TYPE_HEADER,
    method: HttpRequestMethodTypes.GET,
  };

  try {
    const response = await fetchWithRetry(`/api/last-search/handler/${body.userId}?sourceTheme=${body.sourceTheme}`, options);
    const parsedResponse = await response.json();
    if (response.ok) {
      return parsedResponse as GetUserLastSearchResponse;
    } else if ('error' in parsedResponse) {
      const { error } = parsedResponse;
      return new Error(error?.message) || new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    const wrappedError = new Error(`There was an error sending the user's last search: ${(error as Error)?.message}`, { cause: error as Error });
    console.error(wrappedError);
    return wrappedError;
  }
}
