/// <reference types="google.maps" />
import GoogleMap from './google/map';
import AppleMap from './apple/map';

import type { Point } from '../box-positioner/types';
import type { GeoJsonPolygon, Position, PinPos, PopupPosition, Provider, Frame, Pin, Offsets } from './types';
import type { GoogleMappable } from './google/types';
import type { DrawMapOptions, MapkitMappable } from './apple/types';

export default function getMap(provider: Provider): Map {
  if (provider === 'google') {
    return new GoogleMap();
  } else if (provider === 'apple') {
    return new AppleMap();
  } else {
    throw new Error(`Invalid map provider (${provider})`);
  }
}

export interface Map {
  drawMap(options: DrawMapOptions, mapElement: string): void;
  addPin(mappable: GoogleMappable | MapkitMappable, showHover: () => void, removeHover: () => void): void;
  createPin(latitude: number, longitude: number, type?: string): Pin;
  onZoom(zoomListener: () => void): void;
  onPan(panListener: () => void): void;
  removePins(mappable: GoogleMappable | mapkit.Annotation[]): void;
  boundaryFromGeoJsonPolygon(boundary: GeoJsonPolygon): google.maps.LatLng[][] | mapkit.Coordinate[][] | undefined;
  removeOverlay(hoverBoundaryMapPolygon: google.maps.Polygon | mapkit.Overlay): void;
  removeEventListeners(): void;
  zoomIn(): void;
  zoomOut(): void;
  makePolygon(paths: google.maps.LatLng[] | mapkit.Coordinate[]): google.maps.Polygon | mapkit.PolygonOverlay;
  drawBoundaryPolygons(
    activeBoundariesMapPolygons: google.maps.OverlayView[] | mapkit.Overlay[],
    activeBoundariesGeojsonPolygons: GeoJsonPolygon[]
  ): google.maps.Polygon[] | mapkit.Overlay[];
  getHoverPosition(latitude: number, longitude: number, popup: HTMLElement, offSets: Offsets): Point;
  calculateTarget(pinPos: PinPos, frame: Frame | null, xOffset: number, yOffset: number): PopupPosition;
  placeImageMarker(icon: string, position: Position): void;
  directions(origin: string, destination: string, travelMode: string): Promise<unknown>;
  travelDuration(directions: google.maps.DirectionsResult | mapkit.DirectionsResponse): string;
  travelDistance(directions: google.maps.DirectionsResult | mapkit.DirectionsResponse): string;
  drawOverlays(directions: google.maps.DirectionsResult | mapkit.DirectionsResponse): void;
  changeLocation(location: Position, zoom: number): void;
  clearMarkers(): void;
  readonly latitude: number;
  readonly longitude: number;
  readonly zoom: number;
  readonly boundary: GeoJsonPolygon | null;
  readonly annotations: mapkit.Annotation[] | null;
}
