export const PRICE_SORT = 'price';
export const PRICE_DESCENDING_SORT = '-price';
export const DATE_SORT = 'date';
export const DATE_DESCENDING_SORT = '-date';
export const BEDROOMS_SORT = 'bedrooms';
export const BEDROOMS_DESCENDING_SORT = '-bedrooms';
export const BATHROOMS_SORT = 'bathrooms';
export const BATHROOMS_DESCENDING_SORT = '-bathrooms';
export const SORT_KEYS: readonly string[] = [PRICE_SORT, PRICE_DESCENDING_SORT, DATE_SORT, DATE_DESCENDING_SORT, BEDROOMS_SORT, BEDROOMS_DESCENDING_SORT, BATHROOMS_SORT, BATHROOMS_DESCENDING_SORT];
export type Sort = typeof PRICE_SORT | typeof PRICE_DESCENDING_SORT | typeof DATE_SORT | typeof DATE_DESCENDING_SORT | typeof BEDROOMS_SORT | typeof BEDROOMS_DESCENDING_SORT | typeof BATHROOMS_SORT | typeof BATHROOMS_DESCENDING_SORT;

export const AVAILABLE_STATUS = 'available';
export const NOT_AVAILABLE_STATUS = 'not-available';
export const NOT_AVAILABLE_SOLD_STATUS = 'not-available-sold';
export const NOT_AVAILABLE_OTHER_STATUS = 'not-available-other';
export const INACTIVE_STATUS = 'inactive';
export const EXPIRED_STATUS = 'expired';
export const STATUS_KEYS: readonly string[] = [AVAILABLE_STATUS, NOT_AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS, NOT_AVAILABLE_OTHER_STATUS];
export type Status = typeof AVAILABLE_STATUS | typeof NOT_AVAILABLE_STATUS | typeof NOT_AVAILABLE_SOLD_STATUS | typeof NOT_AVAILABLE_OTHER_STATUS;

export const ANY_LOCKER_VALUE = 'any';
export const YES_LOCKER_VALUE = 'yes';
export const NO_LOCKER_VALUE = 'no';
export const LOCKER_VALUES: readonly string[] = [ANY_LOCKER_VALUE, YES_LOCKER_VALUE, NO_LOCKER_VALUE];
export type Locker = typeof ANY_LOCKER_VALUE | typeof YES_LOCKER_VALUE | typeof NO_LOCKER_VALUE;

export type Bedroom = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/** @deprecated use HOUSE_PROPERTY_TYPE instead */
export const HOUSE_DETACHED_PROPERTY_TYPE = 'houseDetached'; 
/** @deprecated use HOUSE_PROPERTY_TYPE instead */
export const HOUSE_SEMIDETACHED_PROPERTY_TYPE = 'houseSemidetached';
/** @deprecated use HOUSE_PROPERTY_TYPE instead */
export const HOUSE_ATTACHED_PROPERTY_TYPE = 'houseAttached';

export const HOUSE_PROPERTY_TYPE = 'house';
export const TOWNHOUSE_PROPERTY_TYPE = 'townhouse';
export const CONDO_PROPERTY_TYPE = 'condo';
export const LAND_PROPERTY_TYPE = 'land';
export const COMMERCIAL_PROPERTY_TYPE = 'commercial';
export const FARM_PROPERTY_TYPE = 'farm';

export type DeprecatedPropertyType = typeof HOUSE_DETACHED_PROPERTY_TYPE |
  typeof HOUSE_SEMIDETACHED_PROPERTY_TYPE |
  typeof HOUSE_ATTACHED_PROPERTY_TYPE;

export type SupportedPropertyType = typeof HOUSE_PROPERTY_TYPE | 
  typeof TOWNHOUSE_PROPERTY_TYPE | 
  typeof CONDO_PROPERTY_TYPE | 
  typeof LAND_PROPERTY_TYPE | 
  typeof COMMERCIAL_PROPERTY_TYPE | 
  typeof FARM_PROPERTY_TYPE;

export type PropertyType = SupportedPropertyType;

/** @deprecated this filter is not used anymore and will be removed in the future */
export type DeprecatedPropertyTypeFilter = {
  /** @deprecated use house instead */  
  houseDetached: boolean;
  /** @deprecated use house instead */
  houseSemidetached: boolean;
  /** @deprecated use house instead */
  houseAttached: boolean;
};

export type PropertyTypeFilter = Record<PropertyType, boolean>;

/** @deprecated this filter is not used anymore */
export type HouseAdditionalFilter = {
  singleFamily: boolean;
  basementApartment: boolean;
  duplex: boolean;
  triplex: boolean;
  'fourplex+': boolean;
};

export type CondoOrTownhouseAdditionalFilter = {
  locker: Locker;
  maintenanceFee: number | null;
};

export type FlattenFilter = Exclude<Filter, 'homeType' | 'additional'> & CondoOrTownhouseAdditionalFilter & PropertyTypeFilter;
export type FilterKeys = keyof Filter;

export type Filter = {
  rental: boolean;
  status: Status;
  slug: string;
  latitude: number;
  longitude: number;
  zoom: number;
  homeType: PropertyTypeFilter;
  priceMin: number | null;
  priceMax: number | null;
  bedrooms: string;
  sqftMin: number | null;
  sqftMax: number | null;
  listedSince: string | null;
  listedTo: string | null;
  bathrooms: string;
  parkingSpaces: string;
  openHouse: boolean;
  garage: boolean;
  pool: boolean;
  fireplace: boolean;
  waterfront: boolean;
  additional: {
    condoOrTownhouse: CondoOrTownhouseAdditionalFilter;
  };
  areaName: string;
  boundary: string | null;
  providerId: string | null;
};

export type Page = {
  number: number;
  size: number;
};

export type ListingParams = {
  page?: Page;
  sort: Sort;
  filter: Filter;
};

export type ListingParamsMethods = {
  setSort: (sort: Sort) => void;
  setFilter: (filter: Filter) => void;
  setSlug: (slug: Filter['slug']) => void;
  setLongitude: (longitude: Filter['longitude']) => void;
  setLatitude: (latitude: Filter['latitude']) => void;
  setZoom: (zoom: Filter['zoom']) => void;
  setStatus: (status: Filter['status']) => void;
  setRental: (rental: Filter['rental']) => void;
  setBedrooms: (bedrooms: Filter['bedrooms']) => void;
  setBathrooms: (bathrooms: Filter['bathrooms']) => void;
  setSqftMin: (sqftMin: Filter['sqftMin']) => void;
  setSqftMax: (sqftMax: Filter['sqftMax']) => void;
  setParkingSpaces: (parkingSpaces: Filter['parkingSpaces']) => void;
  setListedSince: (listedSince: Filter['listedSince']) => void;
  setListedTo: (listedTo: Filter['listedTo']) => void;
  setLocker: (locker: CondoOrTownhouseAdditionalFilter['locker']) => void;
  setMaintenanceFee: (maintenanceFee: CondoOrTownhouseAdditionalFilter['maintenanceFee']) => void;
  setPriceMin: (priceMin: Filter['priceMin']) => void;
  setPriceMax: (priceMax: Filter['priceMax']) => void;
  setPrice: (priceMin: Filter['priceMin'], priceMax: Filter['priceMax']) => void;
  setHomeType: (homeType: PropertyTypeFilter) => void;
  setBoundary: (boundary: Filter['boundary']) => void;
  setAreaName: (areaName: Filter['areaName']) => void;
};