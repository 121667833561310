import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  isExpTheme?: boolean;
}

const LinkedInIcon = ({ className, onClick, isExpTheme }: Props) => {
  return (isExpTheme
    ?
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none" className={className} onClick={onClick}>
      <g id="linkedin">
        <path id="Vector" d="M12.2319 5.87036C13.5465 5.87036 14.8072 6.39256 15.7367 7.32209C16.6662 8.25162 17.1884 9.51233 17.1884 10.8269V16.6095H13.8841V10.8269C13.8841 10.3887 13.71 9.96846 13.4002 9.65862C13.0903 9.34878 12.6701 9.17471 12.2319 9.17471C11.7937 9.17471 11.3735 9.34878 11.0636 9.65862C10.7538 9.96846 10.5797 10.3887 10.5797 10.8269V16.6095H7.27539V10.8269C7.27539 9.51233 7.79759 8.25162 8.72712 7.32209C9.65665 6.39256 10.9174 5.87036 12.2319 5.87036Z" fill="white"/>
        <path id="Vector_2" d="M3.97036 6.69653H0.666016V16.6096H3.97036V6.69653Z" fill="white"/>
        <path id="Vector_3" d="M2.31819 4.21817C3.23066 4.21817 3.97036 3.47846 3.97036 2.56599C3.97036 1.65352 3.23066 0.913818 2.31819 0.913818C1.40572 0.913818 0.666016 1.65352 0.666016 2.56599C0.666016 3.47846 1.40572 4.21817 2.31819 4.21817Z" fill="white"/>
      </g>
    </svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="512pt" height="512pt" viewBox="0 0 512 512" className={className} onClick={onClick}>
      <path
        d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm-74.39 387h-62.348V199.426h62.347zm-31.173-213.188h-.406c-20.922 0-34.453-14.402-34.453-32.402 0-18.406 13.945-32.41 35.274-32.41 21.328 0 34.453 14.004 34.859 32.41 0 18-13.531 32.403-35.274 32.403zM406.423 387h-62.34V286.652c0-25.218-9.027-42.418-31.586-42.418-17.223 0-27.48 11.602-31.988 22.801-1.649 4.008-2.051 9.61-2.051 15.215V387h-62.344s.817-169.977 0-187.574h62.344v26.558c8.285-12.78 23.11-30.96 56.188-30.96 41.02 0 71.777 26.808 71.777 84.421zm0 0"></path>
    </svg>
  );
};

export default LinkedInIcon;
