import dayjs from 'dayjs';
import lengthConverter from 'utils/length-converter';
import timeInterval from 'utils/time-interval';

export class OpenHouse {
  to: string | null;
  from: string | null;
  date: string | null;

  constructor(openHouse: Record<string, unknown>) {
    Object.assign(this, openHouse);
  }

  get isActive() {
    if (this.date) {
      const date = new Date(this.date);
      const hoursMatch = this.to?.match(/^(\d+):/);
      if (hoursMatch) {
        date.setHours(parseInt(hoursMatch[1], 10) + (this.to?.match(/pm/i) ? 12 : 0));
      }
      const minutesMatch = this.to?.match(/:(\d+)/);
      if (minutesMatch) {
        date.setMinutes(parseInt(minutesMatch[1], 10));
      }
      return date > new Date();
    } 
    return false;
  }

  get label() {
    let label = dayjs(this.date).format('ddd. MMM D');
    if (this.from) {
      label += ', ' + timeInterval(this.from, this.to || undefined);
    }
    return label;
  }
}

export class Room {
  type: string | null;
  level: string | null;
  width: number | null;
  length: number | string | null;
  unit: string | null;

  constructor(room: Record<string, unknown>) {
    Object.assign(this, room);
  }

  size = (desiredUnit: string) => {
    const { unit, width, length } = this;
    if (width && length && unit) {
      return `${lengthConverter(width, unit, desiredUnit)} × ${lengthConverter(Number(length), unit, desiredUnit)} ${desiredUnit === 'feet' ? 'ft' : 'm'}`;
    } else {
      return 'Not available';
    }
  };
}