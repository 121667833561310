import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import endpoint, { ErrorResponse, isErrorResponse } from 'utils/endpoint';
import { provinceOrStateNameFromCode, type ProvinceOrStateCode } from 'utils/province_or_state';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import { HttpRequestMethodTypes } from 'types';

type AddressType = 'province' | 'sub-division' | 'neighbourhood' | 'address';

export type AddressSelf = {
  id: number;
  addressType: 'country' | 'province' | 'sub-division' | 'place-name' | 'neighbourhood' | 'address';
  country: string;
  name: string | null;
  neighbourhood: string | null;
  number: number | null;
  postalCode: string | null;
  province: string;
  slug: string;
  subDivision: string | null;
  unit: string | null;
  provinceCode: ProvinceOrStateCode;
};

interface AddressData {
  id: string;
  type: 'addresses';
  attributes: Record<string, unknown>;
  relationships: {
    id: string;
    type: 'addresses';
  }[];
}

interface AddressPayload {
  data: AddressData;
  included: AddressData[];
}

const PRE_US_ADDRESS_ID = '5940087';
const US_ADDRESS_ID = '6120961';

export default class Address {
  id: number;
  addressType: 'country' | 'province' | 'sub-division' | 'place-name' | 'neighbourhood' | 'address';
  country: string;
  name: string | null;
  neighbourhood: string | null;
  number: number | null;
  postalCode: string | null;
  province: string;
  slug: string;
  subDivision: string | null;
  unit: string | null;

  constructor(address: Record<string, unknown>) {
    const camelizedAddress = camelizeKeys(address);
    const attributes = camelizedAddress.attributes as Record<string, unknown>;
    const relationships = camelizedAddress.relationships as Record<
      string,
      unknown
    >;
    const formattedAddress = {
      ...attributes,
      ...relationships,
      id: Number(camelizedAddress.id),
    } as Address;
    Object.assign(this, formattedAddress);
  }

  get provinceCode() {
    return this.province.toLowerCase() as ProvinceOrStateCode;
  }

  get label() {
    const addressType = this.addressType as AddressType;
    let label = '';
    if (addressType === 'province') {
      label = provinceOrStateNameFromCode(this.provinceCode);
    } else if (addressType === 'sub-division' || addressType === String('place-name')) {
      label = this.subDivision as string;
    } else if (addressType === 'neighbourhood') {
      label = this.neighbourhood as string;
    } else if (addressType === 'address') {
      label = `${this.number || ''} ${this.name}`;
    }
    return capitalizeWords(label);
  }

  get isNotAreaPage() {
    return this.addressType === 'address';
  }

  generateLink = (addressPath?: string) => {
    return generateBreadcrumbLink(this, addressPath);
  };
}

export async function getAddressesBySlug(slug: string) {
  try {
    const payload = await endpoint<AddressPayload | ErrorResponse>(`/services/api/v3/addresses/${slug}`);
    if (isErrorResponse(payload)) {
      return [];
    }

    const addresses = [new Address(payload.data as unknown as Record<string, unknown>)];
    if (payload.included && payload.included?.length > 0) {
      for (let index = 0; index < payload.included.length; index++) {
        const addressData = payload.included[index];
        if (addressData?.id !== US_ADDRESS_ID && addressData?.id !== PRE_US_ADDRESS_ID) {
          addresses.push(new Address(addressData as unknown as Record<string, unknown>));
        }
      } 
    }
    return addresses;
  } catch (error){
    console.error(error);
    return [];
  }
}

export async function getAddressesByParams(params: Record<string, unknown>) {
  try {
    const payload = await endpoint<{ data: AddressData[] } | ErrorResponse>('/services/api/v3/addresses', HttpRequestMethodTypes.GET, params);
    if (isErrorResponse(payload)) {
      return [];
    }

    return payload.data.map(i => new Address(i as unknown as Record<string, unknown>));
  } catch (error){
    console.error(error);
    return [];
  }
}

export function toJson(data: any) {
  return JSON.parse(JSON.stringify(data));
}

export function generateBreadcrumbLink(address: AddressSelf, addressPath?: string): string {
  let path = '';
  const addressType = address.addressType as AddressType;
  const { provinceCode, subDivision, neighbourhood } = address;
  const pathSuffix = 'real-estate';

  if (addressType === 'province') {
    path = `/${cleanDashedString(provinceOrStateNameFromCode(provinceCode))}-${pathSuffix}`;
  } else if ((addressType === 'sub-division' || addressType === String('place-name')) && subDivision) {
    path = `/${cleanDashedString(subDivision.toLowerCase())}-${provinceCode}-${pathSuffix}`;
  } else if (addressType === 'neighbourhood' && neighbourhood && subDivision) {
    path = `/${cleanDashedString(subDivision.toLowerCase())}-${provinceCode}-${pathSuffix}/${cleanDashedString((neighbourhood.toLowerCase()))}`;
  } else if (addressType === 'address' && addressPath) {
    path = addressPath;
  }
  return path.toLowerCase();
}

export function cleanDashedString(value: string) {
  return dasherize(value.replace(/[^a-zA-Z0-9-/ ]/g, ''));
}
