import pino, { Logger } from 'pino';
let logger: Logger;
if (process.env.ENV === 'staging' || process.env.ENV === 'production' || process.env.ENV === 'preview') {
  logger = pino({
    level: 'info',
  });
} else {
  logger = pino({
    level: 'debug',
    formatters: {
      level: label => {
        return { level: label.toUpperCase() };
      },
    },
    timestamp: pino.stdTimeFunctions.isoTime,
  });
}

export default logger;