import React from 'react';
import NextLink from 'next/link';
import { getRouteNameFromPath } from 'components/dynamic-page/route-matchers';

import type { AnchorHTMLAttributes } from 'react';

type InternalLinkProps = {
    /** The path or URL to navigate to */
    href: string;
    /** Optional decorator for the path that will be shown in the browser URL bar */
    as?: string;
    /** Specify the CSS class to use in the internal <a> component */
    className?: string;
    /** Where to display the linked URL */
    target?: string;
    children?: React.ReactNode;
    /** Targeted selector that's only used for testing */
    testId?: string;
    /** Aria label for accessibility */
    ariaLabel?: string;
    /** event handler for when Link is clicked */
    onClick?(): void;
};
export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & InternalLinkProps;

const Link = ({ href, as, className, target, children, testId, ariaLabel, onClick }: LinkProps) => {
  if (as && isHardLink(as)) {
    return <a href={as || href} className={className} target={target} onClick={onClick} data-testid={testId} aria-label={ariaLabel}>{children}</a>;
  }
  return <NextLink href={href} as={as} prefetch={false} className={className} target={target} onClick={onClick} data-testid={testId} aria-label={ariaLabel}>{children}</NextLink>;
};

function isHardLink(path: string) {
  const dynamicRoute = getRouteNameFromPath(path) || '';
  if (['area-listings', 'listing'].includes(dynamicRoute) || softLinks.includes(path)) {
    return false;
  } else {
    return true;
  }
}

export const softLinks: string[] = [
  '/account/saved/properties',
  '/account/saved/searches',
  '/account/saved/favourites',
];

export default Link;