import type { IncomingMessage } from 'http';

export default function getRequestHeader(name: string, req?: IncomingMessage) {
  if (req) {
    const stringValue = req.headers[name.toLocaleLowerCase()] as string;
    if (stringValue) {
      const values = stringValue.split(',');
      const requestHeader: Record<string, boolean> = {};
      values.forEach(value => {
        const [feature, isActive] = value.split('=');
        requestHeader[feature.trim()] = isActive.trim() === 'true';
      });
      return requestHeader;
    }
  }
}