import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  isExpTheme?: boolean;
}

const InstagramIcon = ({ className, onClick, isExpTheme }: Props) => {
  return (isExpTheme
    ?
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" className={className} onClick={onClick}>
      <g id="instagram" clipPath="url(#clip0_22012_45025)">
        <path id="Vector" d="M12.9375 2.26172H5.4375C3.36643 2.26172 1.6875 3.94065 1.6875 6.01172V13.5117C1.6875 15.5828 3.36643 17.2617 5.4375 17.2617H12.9375C15.0086 17.2617 16.6875 15.5828 16.6875 13.5117V6.01172C16.6875 3.94065 15.0086 2.26172 12.9375 2.26172Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M12.1883 9.28847C12.2808 9.91265 12.1742 10.5501 11.8836 11.1102C11.593 11.6703 11.1331 12.1245 10.5695 12.4082C10.0058 12.6919 9.36709 12.7907 8.7441 12.6904C8.1211 12.5902 7.54558 12.296 7.09939 11.8498C6.65319 11.4036 6.35906 10.8281 6.25881 10.2051C6.15856 9.58213 6.25731 8.94339 6.541 8.37975C6.8247 7.81611 7.2789 7.35627 7.839 7.06565C8.39909 6.77502 9.03657 6.66841 9.66076 6.76097C10.2974 6.85538 10.8869 7.15207 11.342 7.6072C11.7972 8.06233 12.0938 8.65178 12.1883 9.28847Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M13.3125 5.63672H13.3208" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_22012_45025">
          <rect width="18" height="18" fill="white" transform="translate(0.1875 0.761719)"/>
        </clipPath>
      </defs>
    </svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="512pt" height="512pt" viewBox="0 0 512 512" className={className} onClick={onClick}>
      <path d="M305 256c0 27.063-21.938 49-49 49s-49-21.938-49-49 21.938-49 49-49 49 21.938 49 49zm0 0"></path>
      <path d="M370.594 169.305a45.546 45.546 0 00-10.996-16.903 45.514 45.514 0 00-16.903-10.996c-5.18-2.011-12.96-4.406-27.293-5.058-15.504-.707-20.152-.86-59.402-.86-39.254 0-43.902.149-59.402.856-14.332.656-22.118 3.05-27.293 5.062a45.483 45.483 0 00-16.903 10.996 45.572 45.572 0 00-11 16.903c-2.011 5.18-4.406 12.965-5.058 27.297-.707 15.5-.86 20.148-.86 59.402 0 39.25.153 43.898.86 59.402.652 14.332 3.047 22.114 5.058 27.293a45.563 45.563 0 0010.996 16.903 45.514 45.514 0 0016.903 10.996c5.18 2.015 12.965 4.41 27.297 5.062 15.5.707 20.144.856 59.398.856 39.258 0 43.906-.149 59.402-.856 14.332-.652 22.118-3.047 27.297-5.062a48.68 48.68 0 0027.899-27.899c2.011-5.18 4.406-12.96 5.062-27.293.707-15.504.856-20.152.856-59.402 0-39.254-.149-43.902-.856-59.402-.652-14.332-3.047-22.118-5.062-27.297zM256 331.485c-41.691 0-75.488-33.794-75.488-75.485s33.797-75.484 75.488-75.484c41.688 0 75.484 33.793 75.484 75.484S297.688 331.484 256 331.484zm78.469-136.313c-9.742 0-17.64-7.899-17.64-17.64s7.898-17.641 17.64-17.641 17.64 7.898 17.64 17.64c-.004 9.742-7.898 17.64-17.64 17.64zm0 0"></path>
      <path d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm146.113 316.605c-.71 15.649-3.199 26.333-6.832 35.684a75.164 75.164 0 01-42.992 42.992c-9.348 3.633-20.035 6.117-35.68 6.832-15.675.715-20.683.887-60.605.887-39.926 0-44.93-.172-60.61-.887-15.644-.715-26.331-3.199-35.68-6.832a72.018 72.018 0 01-26.038-16.957 72.044 72.044 0 01-16.953-26.035c-3.633-9.348-6.121-20.035-6.832-35.68-.723-15.68-.891-20.687-.891-60.609s.168-44.93.887-60.605c.71-15.649 3.195-26.332 6.828-35.684a72.013 72.013 0 0116.96-26.035 72.003 72.003 0 0126.036-16.957c9.352-3.633 20.035-6.117 35.684-6.832C211.07 109.172 216.078 109 256 109s44.93.172 60.605.89c15.649.712 26.332 3.196 35.684 6.825a72.061 72.061 0 0126.04 16.96 72.027 72.027 0 0116.952 26.036c3.637 9.352 6.121 20.035 6.836 35.684.715 15.675.883 20.683.883 60.605s-.168 44.93-.887 60.605zm0 0"></path>
    </svg>
  );
};

export default InstagramIcon;
