import googleGeocode from './api/geocoder';

export type GeocoderResponseType = { latitude: number; longitude: number };

export type GoogleGeocoderType = (
    placeId: string
  ) => Promise<google.maps.GeocoderResult | undefined>;

export async function findPlaceByPlaceId(placeId: string) {
  const result = await googleGeocode(placeId);
  return result;
}

export async function findLocationByPlaceId(placeId: string, geocode: GoogleGeocoderType = googleGeocode) {
  const result = await geocode(placeId);
  if (result) {
    const location: GeocoderResponseType = {
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    };
    return location;
  }

  return undefined;
}

export async function findLocationBoundaryByPlaceId(placeId: string, geocode: GoogleGeocoderType = googleGeocode) {
  const result = await geocode(placeId);
  if (result) {
    const boundary = {
      type: 'Polygon',
      coordinates: [
        [
          [result?.geometry?.bounds?.getNorthEast()?.lng(), result?.geometry?.bounds?.getNorthEast()?.lat()],
          [result?.geometry?.bounds?.getSouthWest()?.lng(), result?.geometry?.bounds?.getSouthWest()?.lat()],
        ],
      ],
    };
    return JSON.stringify(boundary);
  }

  return undefined;
}
