import logger from './logger';

function formatWithSpecifiers(fmt: string, ...args: any[]): string {
  let i = 0;
  // Replace the format specifiers with corresponding arguments
  const formattedString = fmt.replace(/%[sdifo]/g, match => {
    const arg = args[i++];
    switch (match) {
    case '%s':
      return String(arg);
    case '%d':
    case '%i':
      return parseInt(arg, 10).toString();
    case '%f':
      return parseFloat(arg).toString();
    case '%o':
      return typeof arg === 'object' ? JSON.stringify(arg) : String(arg);
    default:
      return match;
    }
  });

  // If there are still remaining arguments that weren't matched by format specifiers, append them
  const remainingArgs = args.slice(i).map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : String(arg)));

  return remainingArgs.length > 0 ? formattedString + ' ' + remainingArgs.join(' ') : formattedString;
}

function formatLogArguments(message?: any, ...optionalParams: any[]): string {
  if (typeof message === 'string') {
    return formatWithSpecifiers(message, ...optionalParams);
  } else {
    return [message, ...optionalParams]
      .map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : String(arg)))
      .join(' ');
  }
}

console.error = (message?: any, ...optionalParams: any[]) => {
  const formattedMessage = formatLogArguments(message, ...optionalParams);
  logger.error(formattedMessage);
};

console.info = (message?: any, ...optionalParams: any[]) => {
  const formattedMessage = formatLogArguments(message, ...optionalParams);
  logger.info(formattedMessage);
};

console.debug = (message?: any, ...optionalParams: any[]) => {
  const formattedMessage = formatLogArguments(message, ...optionalParams);
  logger.debug(formattedMessage);
};

console.trace = (message?: any, ...optionalParams: any[]) => {
  const formattedMessage = formatLogArguments(message, ...optionalParams);
  logger.trace(formattedMessage);
};

console.log = (message?: any, ...optionalParams: any[]) => {
  const formattedMessage = formatLogArguments(message, ...optionalParams);
  logger.info(formattedMessage);
};

console.warn = (message?: any, ...optionalParams: any[]) => {
  const formattedMessage = formatLogArguments(message, ...optionalParams);
  logger.warn(formattedMessage);
};
