import fetch from 'isomorphic-unfetch';
import { ZOOCASA_API_KEY_HEADER_NAME } from 'constants/headers';
import { RETRY_BACKOFF, RETRY_ON_STATUS } from 'constants/retry';
import { FetchOptions, MiddlewareFunction } from 'types/fetchWithRetry';

export const DEFAULT_RETRIES = 0;
export const DEFAULT_BASE_DELAY = 1000;
export const DEFAULT_MAX_DELAY = 30000;

const urlInterceptSegments = [
  'http://localhost:4200',
  'https://search-stage.zoocasa.com',
  'https://consent-stage.zoocasa.com',
  'https://stats.zoocasa.com',
  '/services/storage-service',
  '/api/last-search',
  '/insights',
  '/api/search',
  '/services/pre-construction',
  '/services/providers-compliance',
  '/api/map',
  '/api/consent-disclaimers',
  '/api/viewed-listings',
  '/services/api/v3',
  '/services/api/v4',
  '/api/search-predictions',
];

class MiddlewareManager {
  static instance: any;
  globalMiddleware: MiddlewareFunction | null = null;
  constructor() {
    if (MiddlewareManager.instance) {
      return MiddlewareManager.instance;
    }
    this.globalMiddleware = null;
    MiddlewareManager.instance = this;
  }

  static getInstance() {
    if (!MiddlewareManager.instance) {
      MiddlewareManager.instance = new MiddlewareManager();
    }
    return MiddlewareManager.instance;
  }

  /**
   * Set the global middleware.
   *
   * The global middleware will be used by `fetchWithRetry` if no middleware is provided directly
   * to the function. The priority for middleware is as follows:
   *
   * 1. Middleware passed directly to {@link fetchWithRetry}.
   * 2. Global middleware set by {@link setGlobalMiddleware}.
   * 3. {@link defaultMiddleware} if neither of the above is set.
   *
   * @param middleware The middleware function to set globally.
   */
  setGlobalMiddleware(middleware: MiddlewareFunction) {
    this.globalMiddleware = middleware;
  }

  getGlobalMiddleware() {
    return this.globalMiddleware;
  }
}

export default MiddlewareManager.getInstance();

// Define a default middleware
export const defaultMiddleware: MiddlewareFunction = async (url, options) => {
  const apiKey = process.env.NEXT_PUBLIC_ZOOCASA_API_KEY;

  if (apiKey && apiKey.length > 0 && urlInterceptSegments.some(segment => url.includes(segment))) {
    options.headers = new Headers(options.headers);
    options.headers.append(ZOOCASA_API_KEY_HEADER_NAME, apiKey);
  }

  return options;
};

/**
 * Fetch with retry functionality.
 *
 * A wrapper around the standard  [fetch](https://developer.mozilla.org/docs/Web/API/fetch) function that adds retry and backoff strategy.
 * It also allows applying a middleware function that lets the client modify the fetch options.
 *
 * @param url The URL to fetch.
 * @param options Fetch options.
 * @param retryOnStatus Status codes to retry on. The request will be retried if the response status matches any of these codes. Default: {@link RETRY_ON_STATUS}
 * @param retryBackoff Backoff time (in milliseconds) between retries. Default: {@link RETRY_BACKOFF}
 * @param middleware Middleware function to apply to the fetch options. If not provided, the global middleware or default middleware will be used.
 *
 * @returns  The fetch response.
 *
 * @throws Throws an error if all retry attempts fail.
 *
 * @example
 * // Basic usage with default middleware
 * const response = await fetchWithRetry('https://api.example.com/data');
 *
 * @example
 * // Usage with custom options and middleware
 * const customMiddleware = async (url, options) => {
 *   options.headers = new Headers(options.headers);
 *   options.headers.append('Authorization', 'Bearer YOUR_TOKEN');
 *   return options;
 * };
 *
 * const response = await fetchWithRetry('https://api.example.com/data', {}, [500, 502], 1000, customMiddleware);
 */
export async function fetchWithRetry(url: string, options: FetchOptions = {}, retryOnStatus = RETRY_ON_STATUS, retryBackoff = RETRY_BACKOFF, middleware: MiddlewareFunction = null) {
  const { retries = DEFAULT_RETRIES, baseDelay = DEFAULT_BASE_DELAY, maxDelay = DEFAULT_MAX_DELAY } = retryBackoff || {};
  let delay = baseDelay;
  let retryCount = 0;

  // Use the provided middleware if set, otherwise fall back to global middleware, and then default middleware
  const globalMiddleware = MiddlewareManager.getInstance().getGlobalMiddleware();
  const appliedMiddleware = middleware || globalMiddleware || defaultMiddleware;
  // Apply middleware to options
  const updatedOptions = await appliedMiddleware(url, options);

  for (let i = 0; i <= retries; i++) {
    const response = await fetch(url, updatedOptions);
    if (!retryOnStatus || !retryOnStatus.statusCodes.includes(response.status)) {
      return response;
    }
    retryCount++;
    if (retryCount > retries) {
      return response;
    }
    if (delay < maxDelay) {
      delay *= 2;
    }
    await new Promise(resolve => setTimeout(resolve, delay));
  }
  return new Response();
}
