import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import configJSON from 'config.json';
import { useRouter } from 'next/router';

export type FeaturesType = typeof configJSON.features;

export interface IFeatures {
  features: FeaturesType;
  setFeatures: React.Dispatch<React.SetStateAction<FeaturesType>>;
  areDelayedScriptsLoaded: boolean;
  isWebView: boolean;
  hasInteractedWithSite: boolean;
  isMobile: boolean;
  isSearchPanelOpen: boolean;
  setIsSearchPanelOpen: (isOpen: boolean) => void;
  closeSearchPanel: () => void;
}

export const FeaturesContext = createContext<IFeatures | Record<string, unknown>>({});

export function useFeaturesContext <T extends IFeatures | Record<string, unknown> = IFeatures>() {
  return useContext(FeaturesContext) as T;
}

interface FeaturesContextProviderProps {
  features: FeaturesType;
  isWebView: boolean;
  isMobile: boolean;
  children: ReactNode;
}

export default function FeaturesContextProvider({ children, features: requestHeaderFeatures, isWebView, isMobile }: FeaturesContextProviderProps) {
  const [features, setFeatures] = useState<FeaturesType>({ ...configJSON.features, ...requestHeaderFeatures });
  const [areDelayedScriptsLoaded, setAreDelayedScriptsLoaded] = useState(false);
  const [hasInteractedWithSite, setHasInteractedWithSite] = useState(false);
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState<IFeatures['isSearchPanelOpen']>(false);
  const router = useRouter();

  useEffect(() => {
    isSearchPanelOpen && setIsSearchPanelOpen(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const loadDelayedScripts = () =>
    setAreDelayedScriptsLoaded(true);

  const keyboardInteraction = () => {
    loadDelayedScripts();
    setHasInteractedWithSite(true);
  };

  useEffect(() => {
    // Load delayed scripts on active user
    window.addEventListener('mousemove', loadDelayedScripts, { passive: true, once: true });
    window.addEventListener('scroll', loadDelayedScripts, { passive: true, once: true });
    window.addEventListener('click', keyboardInteraction, { passive: true, once: true });
    window.addEventListener('touchstart', keyboardInteraction, { passive: true, once: true });
    window.addEventListener('keydown', keyboardInteraction, { passive: true, once: true });

    // Prevent Chrome 76 and later from showing the mini-infobar (chrome app install mini banner)
    window.addEventListener('beforeinstallprompt', (e: Event) => e.preventDefault(), { passive: true });
    
    return () => {
      window.removeEventListener('mousemove', loadDelayedScripts);
      window.removeEventListener('scroll', loadDelayedScripts);
      window.removeEventListener('click', keyboardInteraction);
      window.removeEventListener('touchstart', keyboardInteraction);
      window.removeEventListener('keydown', keyboardInteraction);
      window.removeEventListener('beforeinstallprompt', (e: Event) => e.preventDefault());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeaturesContext.Provider value={{ features, setFeatures, areDelayedScriptsLoaded, isWebView, hasInteractedWithSite, isMobile, isSearchPanelOpen, setIsSearchPanelOpen }}>
      {children}
    </FeaturesContext.Provider>
  );
}
