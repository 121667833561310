import { useEffect } from 'react';

import type { IPreferences } from 'contexts/preferences';

type AuthenticationDetail = {
  type: 'authentication';
  data?: Record<string, unknown>;
};

type ModalDetail = {
  type: 'modal-sign-in' | 'modal-listing' | 'modal-connect-with-agent';
  data: Record<string, string>;
};

type ListingParamsDetail = {
  type: 'listing-params';
  data: IPreferences['listingParams'];
};

type OpenFiltersDetail = {
  type: 'open-filters';
  data: Record<string, unknown>;
};

type NextDetail = AuthenticationDetail | ListingParamsDetail;

export function notifyEmber(detail: NextDetail) {
  document.body.dispatchEvent(new CustomEvent('ember.js', { detail }));
}

export type EmberDetail = ModalDetail | ListingParamsDetail | OpenFiltersDetail;

export function useNotificationFromEmber(callback: (detail: EmberDetail) => void) {
  useEffect(() => {
    const handler = (event: any) => callback(event.detail);
    document.body.addEventListener('next.js', handler, { passive: true });
    return () => document.body.removeEventListener('next.js', handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
