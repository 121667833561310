import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const PhoneIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M29.372 20.681l-3.33-2.38a3.209 3.209 0 00-4.148.344l-1.952 1.952c-2.501-1.773-8.045-7.316-9.819-9.82l1.952-1.951a3.209 3.209 0 00.343-4.147l-2.38-3.331A3.22 3.22 0 005.144.943L2.07 4.016c-2.762 2.762-2.76 6.5.006 10.255 4.387 5.956 8.417 9.986 14.373 14.373 3.473 2.558 7.292 2.968 10.255.006l3.073-3.074a3.22 3.22 0 00-.405-4.895zm-1.291 3.198l-3.074 3.074c-2.131 2.131-4.717 1.54-7.135-.242-5.745-4.231-9.632-8.118-13.864-13.863-1.78-2.417-2.373-5.003-.241-7.135L6.84 2.639a.818.818 0 011.246.104l2.38 3.33a.816.816 0 01-.088 1.056L7.781 9.726a1.2 1.2 0 00-.283 1.25c.828 2.332 9.917 11.42 12.246 12.246a1.2 1.2 0 001.25-.283l2.597-2.597a.817.817 0 011.056-.088l3.33 2.38a.819.819 0 01.104 1.245z"></path>
        </g>
      </g>
    </svg>
  );
};

export default PhoneIcon;
