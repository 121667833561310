import { loadScript } from 'utils/load-file';
import SearchPrediction, { createSearchPredictionModel } from 'data/search-predictions';
import getMap from '@zoocasa/node-kit/map/get-map';
import { ThemeNames } from 'types/themes';

let mapkit: any = null;

interface MapkitPoint {
  coordinate: {
    latitude: number;
    longitude: number;
  };
}

interface MapkitAutocompleteResult {
  query: string;
  results: (MapkitPoint & {
    displayLines: string[];
  })[];
}

type MapkitGeocoderLookupResponse = {
  results: MapkitPoint[];
};

export const transitionZoomLevel = 13;

export async function query(query: string, isCommute = false): Promise<SearchPrediction[]> {
  const mapkitSearch = await loadMapKitSearch();
  return new Promise((resolve, reject) => {
    mapkitSearch.autocomplete(query, (err: Record<string, unknown>, data: MapkitAutocompleteResult) => {
      if (err) {
        return reject(err);
      } else {
        const searchPredictions = filterAndFormatSearchResults(data.results, isCommute).map(createSearchPredictionModel);
        return resolve(searchPredictions);
      }
    });
  });
}

export async function drawInitialMap(mapElementID: string, position: any, isInteractive = false, theme: ThemeNames, renderPin = true) {
  await loadMapKit();
  const map = getMap('apple') as any;
  const [longitude, latitude] = position.coordinates;
  map.drawMap({ latitude: latitude, longitude: longitude, zoom: 14, showsCompass: true, showsZoomControls: true, showsMapTypeControl: false, isScrollEnabled: isInteractive }, mapElementID);
  if (!renderPin) return map;
  if (theme === ThemeNames.ZOOCASA) {
    map.placeImageMarker('/assets/images/current-location-pin.svg', { lat: latitude, lng: longitude });
  } else {
    map.placeImageMarker('/assets/images/current-location-pin-exp.svg', { lat: latitude, lng: longitude });
  }
  return map;
}

export async function getDirectionAndAddOverlays(map: any, origin: string, destination: string, isDriving: boolean): Promise<Record<string, number>> {
  await loadMapKit();
  try {
    const transportType = isDriving ? 'driving' : 'walking';
    const directions = await map.directions(destination, origin, transportType);
    if (map.map.overlays.length) {
      map.clearMarkers();
    }
    map.drawOverlays(directions);
    const routeInformation = directions.routes[0];
    const { distance, expectedTravelTime } = routeInformation;
    return { distance, expectedTravelTime };
  } catch {
    return { distance: 0, expectedTravelTime: 0 };
  }
}

export async function getPositionFromAddress(address: string): Promise<MapkitGeocoderLookupResponse> {
  await loadMapKit();
  const geocoder = new mapkit.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.lookup(address, (err: Record<string, unknown>, data: MapkitGeocoderLookupResponse) => {
      if (err) {
        return reject(err);
      } else {
        return resolve(data);
      }
    });
  });
}

export async function getAddressFromPosition(latitude: number, longitude: number) {
  await loadMapKit();
  const geocoder = new mapkit.Geocoder();
  return new Promise((resolve, reject) => {
    const coordinate = new mapkit.Coordinate(latitude, longitude);
    geocoder.reverseLookup(coordinate, (err: Record<string, unknown>, data: MapkitGeocoderLookupResponse) => {
      if (err) {
        return reject(err);
      } else {
        return resolve(data);
      }
    });
  });
}

export async function loadMapKit() {
  try {
    if (!(window as any).mapkit) {
      await loadScript({ source: 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js' });
    }
    if ((window as any)['mapkit'] && !mapkit) {
      mapkit = (window as any)['mapkit'];
      const initMapKitPromise = new Promise<void>(resolve => {
        mapkit.init({
          authorizationCallback: function (done_1: (arg0: string) => void) {
            resolve(done_1((process.env.NEXT_PUBLIC_APPLE_MAPKIT_JWT as string)));
          },
        });
      });
      const initializationEventPromise = new Promise<void>((resolve, reject) => {
        mapkit.addEventListener('configuration-change', function(event: { status: string }) {
          switch (event.status) {
          case 'Initialized':
            // MapKit JS initialized and configured.
            resolve();
            break;
          }
        });
        mapkit.addEventListener('error', function(event: { status: any }) {
          switch (event.status) {
          case 'Unauthorized':
            console.error('The MapKit JS Initialized Was Unauthorized.');
            reject(event.status);
            break;
          case 'Too Many Requests':
            console.error('Exceeded number of requests allowed.');
            reject(event.status);
            break;
          }
        });
      });
      await Promise.all<[Promise<void>, Promise<void>]>([initMapKitPromise, initializationEventPromise]);
    }
  } catch (e: any) {
    console.error(e);
  }
}

function filterAndFormatSearchResults(results: MapkitAutocompleteResult['results'], isCommute = false) {
  return results.filter(({ displayLines, coordinate }) => {
    return coordinate && filterForCanadianAreas(displayLines, isCommute);
  }).map(result => {
    const { latitude, longitude } = result.coordinate;
    return {
      id: `${Math.random()}`,
      description: `${result.displayLines.join(', ')}`,
      group: 'locations',
      matchedSubstrings: [],
      data: {
        position: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      },
      source: 'apple',
    } as unknown as SearchPrediction;
  });
}

export function filterForCanadianAreas(result: string[], isCommute = false) {
  if (isCommute) {
    return !!(result[1] && result?.[1].match(/^[^\d]+Canada$/));
  }
  return !!(result[1] && result?.[1].match(/^[^\d]+Canada$/) && result?.[0].match(/^([^0-9]*)$/));
}

async function loadMapKitSearch() {
  await loadMapKit();
  const center = new mapkit.Coordinate(43.650848, -79.366969);
  const span = new mapkit.CoordinateSpan(0.001, 0.001);
  const region = new mapkit.CoordinateRegion(center, span);
  return new mapkit.Search({ region, includeAddresses: true, includeQueries: true });
}
