import { transitionZoomLevel } from 'utils/apple-maps';
import { SEARCH_PREDICTION_SOURCE_GOOGLE } from './google-maps/types';
import { dasherize } from '@zoocasa/node-kit/strings';
import { provinceOrStateCodeFromSlug } from './province_or_state';

export const PROVINCE_STATE_ZOOM_LEVEL = 5;

export default function determineZoomLevel(prediction: any) {
  let zoom = transitionZoomLevel;

  if (prediction.label === 'neighbourhood') {
    zoom = 15;
  }

  if (prediction.source === SEARCH_PREDICTION_SOURCE_GOOGLE && prediction.label === 'street') {
    zoom = 16;
  }

  // If the prediction is a province, zoom out to show the entire province
  const isProvinceOrState = provinceOrStateCodeFromSlug(dasherize(prediction.description));
  if (isProvinceOrState) {
    zoom = PROVINCE_STATE_ZOOM_LEVEL;
  }

  if (prediction.source === 'apple') {
    // The number of commas is a rough estimation of the specificity of the result
    // More commas, the more specific the adddress, the closer zoomed in the user should be.
    const numberOfCommas = prediction.description.replace(/[^,]/g, '').length;
    if (numberOfCommas > 2) {
      zoom = 16;
    } else if (numberOfCommas <= 2) {
      zoom = 13;
    }
  }
  return zoom;
}