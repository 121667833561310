import React from 'react';
import openFeedback from 'utils/feedbackify';
import styles from './style.module.scss';
import Button from 'components/control/button';
import { buildClassName } from 'utils/build-class-name';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  themeName?: ThemeNames;
}

export default function Feedbackify({ className, themeName=ThemeNames.ZOOCASA }: Props) {

  return <Button label="Send Us Feedback" expHomePadding={themeName !== ThemeNames.ZOOCASA} onClick={openFeedback} theme='secondary' className={buildClassName(styles.feedback, className)} />;
}
