import type { GeoJsonPolygon } from '../types';

export default function googleBoundaryFromGeojsonPolygon(geojsonPolygon: GeoJsonPolygon) {
  return geojsonPolygon.coordinates.map(coordinates => {
    coordinates = coordinates.slice(0, coordinates.length - 1);
    return coordinates.map(coodinate => {
      const [lng, lat] = coodinate;
      return new google.maps.LatLng(lat, lng);
    });
  });
}
