import React from 'react';
import NoImageIcon from 'components/icon/noimage-icon';
import NoImageExpIcon from 'components/icon/noimageexp-icon';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';

interface Props {
  isRecent?: boolean;
  isExpTheme?: boolean;
}

export default function NoImage({ isRecent, isExpTheme }: Props) {
  return <div className={buildClassName(styles.component, isExpTheme && styles.round)}>
    {isExpTheme ? <NoImageExpIcon /> : <NoImageIcon />}
    {
      isRecent ? 
        <p>This listing is new&#10024;<br/>Photos coming soon</p> :
        <p>Photos Coming Soon</p>
    }
  </div>;
}
