import React from 'react';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

export default function LoadingSkeleton({ className, width = '100%', height = '100%' }: Props) {
  return <div className={ `${styles.component} ${className}` } style={ { width, height } } data-testid={testIds.loadingSkeleton} />;
}
