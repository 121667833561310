export const EMBER_PAGE_URLS = [
  '/education-centre',
  '/education-centre/buying',
  '/education-centre/selling',
  '/company',
  '/company/our-story',
  '/company/covid-19',
  '/company/our-team',
  '/company/our-agents',
  '/terms-of-use',
  '/privacy-policy',
];
