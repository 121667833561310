import { HttpStatusCode } from 'types';
import { RetryBackoffOptions, RetryOnStatusOptions } from 'types/fetchWithRetry';

export const RETRY_ON_STATUS: RetryOnStatusOptions = { statusCodes: [
  HttpStatusCode.INTERNAL_SERVER_ERROR, 
  HttpStatusCode.TOO_MANY_REQUESTS, 
  HttpStatusCode.BAD_GATEWAY,
  HttpStatusCode.GATEWAY_TIMEOUT,
  HttpStatusCode.SERVICE_UNAVAILABLE,
  HttpStatusCode.UNPROCESSABLE_ENTITY,
  HttpStatusCode.BAD_REQUEST,
]} as const;
  
export const RETRY_BACKOFF: RetryBackoffOptions = { retries: 3 } as const;
