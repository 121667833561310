export default {
  isMobile(): boolean {
    if (window.navigator) {
      const platform = window.navigator.platform.toLowerCase();
      const platformName = this.userAgent().match(/ip(?:ad|od|hone)/) ? 'ios' : (this.userAgent().match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0];
      return /ios|android|webos/.test(platformName);
    }
    return false;
  },
  isIE(): boolean {
    return this.checkMatch('trident');
  },
  isSafari(): boolean {
    return this.checkMatch('safari') && !this.checkMatch('crios') && !this.checkMatch('chrome');
  },
  isIOS(): boolean {
    return this.checkMatch('(iphone|ipod|ipad)');
  },
  isIOSLessThanTen(): boolean {
    return this.checkMatch('(iphone|ipod|ipad) os \\d_'); // match for single digit which include 8, 9, but not 10
  },

  // ----- Private Functions -----
  userAgent(): string {
    return window.navigator.userAgent.toLowerCase();
  },
  checkMatch(text: string): boolean {
    return new RegExp(text).test(this.userAgent());
  },
};

export function isMobileSearchView(): boolean {
  if (window.matchMedia) {
    return window.matchMedia('(max-width: 500px)').matches;
  }
  return false;
}
