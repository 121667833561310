import ExprealtyCaTheme from './exprealtyCA';
import ExprealtyUsTheme from './exprealtyUS';
import ZoocasaTheme from './zoocasa';
import ExpCommercialTenant from './expCommercial';

export type { ThemeConfig } from './themeConfig';

export const themes = {
  zoocasa: ZoocasaTheme,
  exprealtyCA: ExprealtyCaTheme,
  exprealtyUS: ExprealtyUsTheme,
  expCommercial: ExpCommercialTenant,
};

export type ThemeName = keyof typeof themes;
