// import googlePositionFromGeojsonPoint from './google-position-from-geojson-point';

// import type { GoogleMappable } from './types';

export default {
  mappableOverlayClass: null,

  defineMappableOverlay(): void {
    // TODO: Fix TypeScript errors before uncommenting this code and the GoogleMap in get-maps.ts
    // const MappableOverlay = function(map: google.maps.Map, mappable: GoogleMappable): void { // Initializer
    //   this.mappable = mappable;
    //   this.element = null;
    //   this.availableEventListeners = ['mouseenter', 'mouseleave', 'click'];
    //   this.setMap(map); // Draws the pins when intializing
    // };

    // MappableOverlay.prototype = new google.maps.OverlayView();

    // MappableOverlay.prototype.onAdd = function(): void {
    //   this.element = this.mappable.pinElement;
    //   this.getPanes().overlayMouseTarget.appendChild(this.element);

    //   // Enables 'click' events
    //   this.availableEventListeners.forEach(eventName => {
    //     google.maps.event.addDomListener(this.element, eventName, () => {
    //       google.maps.event.trigger(this, eventName);
    //     });
    //   });
    // },

    // MappableOverlay.prototype.onRemove = function(): void {
    //   this.element.parentNode.removeChild(this.element);
    // };

    // MappableOverlay.prototype.draw = function(): void {
    //   const googlePosition = googlePositionFromGeojsonPoint(this.mappable.position);

    //   const pinPositionInPixels = this.getProjection().fromLatLngToDivPixel(googlePosition);
    //   this.mappable.pinPositionInPixels = pinPositionInPixels;
    //   this.element.style.left = `${pinPositionInPixels.x}px`;
    //   this.element.style.top = `${pinPositionInPixels.y}px`;
    // };
    // this.mappableOverlayClass = MappableOverlay;
  },

  addMappableOverlayClickHandler(overlay: google.maps.OverlayView, showHover: () => void, removeHover: () => void): void {
    overlay.addListener('mouseenter', function() { showHover(); });
    overlay.addListener('click', function() { showHover(); });
    overlay.addListener('mouseleave', function() { removeHover(); });
  },

  removeMappableOverlay(overlay: google.maps.OverlayView): void {
    overlay.setMap(null);
    google.maps.event.clearInstanceListeners(overlay);
  },
};
