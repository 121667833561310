import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface Boundary {
  type: string;
  coordinates: [number, number][][];
}

export interface IOverlayContext {
  mapBoundary: {
    type: string;
    coordinates: number[];
  };
  filterBoundaries: Boundary[];
  setFilterBoundaries: (boundary: Boundary[]) => void;
  overlays: any;
  setOverlays: any;
  setMapBoundary: (boundary: any) => void;
}

export const OverlayContext = createContext<IOverlayContext | Record<string, unknown>>({});
interface Props {
  children: ReactNode;
}

export default function OverlayContextProvider({ children }: Props) {
  const [mapBoundary, setMapBoundary] = useState(null);
  const [filterBoundaries, setFilterBoundaries] = useState<Boundary[]>([]);
  const [overlays, setOverlays] = useState([]);

  return (
    <OverlayContext.Provider value={{
      mapBoundary,
      setMapBoundary,
      filterBoundaries,
      setFilterBoundaries,
      overlays,
      setOverlays,
    }}>
      {children}
    </OverlayContext.Provider>
  );
}


export function useOverlayContext <T extends IOverlayContext | Record<string, unknown> = IOverlayContext>() {
  return useContext(OverlayContext) as T;
}
