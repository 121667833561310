export const AgreementTypes = {
  ITSO_VOW: 'ITSO_VOW',
  CREA: 'CREA',
  PILLAR9_VOW: 'PILLAR9_VOW',
  REBGV_VOW: 'REBGV_VOW',
};

export const AgreementStorageTypes = {
  ITSO_VOW: 'accept-itso-vow-agreement',
  CREA: 'accept-crea-agreement',
  PILLAR9_VOW: 'accept-pillar9-vow-agreement',
  REBGV_VOW: 'accept-rebgv-vow-agreement',
};

export enum ProviderTypes {
  STELLAR_MLS = 'stellar-mls',
}

export type Provider =
  ProviderTypes.STELLAR_MLS;
