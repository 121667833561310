import Cookies from 'js-cookie';
import { getJSON, getObjectFromRequestCookie } from 'utils/cookies';
import defaultSchoolParams from 'contexts/preferences/school/defaults';
import { SCHOOL_PARAMS_COOKIE_NAME } from 'constants/cookies';

import type { IncomingMessage } from 'http';
import type { SchoolParams as TSchoolParams } from './types';

export const storageKey = SCHOOL_PARAMS_COOKIE_NAME;

export function extractSchoolParamsData(req?: IncomingMessage): TSchoolParams {
  const showSchools = !!req?.url?.includes('show-schools=true');
  const schoolCookie: TSchoolParams | undefined = getObjectFromRequestCookie<TSchoolParams>(storageKey, req) || getJSON(Cookies.get(storageKey));
  if (schoolCookie) {
    if (showSchools) {
      return { ...schoolCookie, showSchools: true };
    } else {
      return schoolCookie;
    }
  } else {
    return { ...defaultSchoolParams, showSchools };
  }

}
