import { CountryCodeList } from 'types/countries';

export const defaultCACityPayload = {
  name: 'Toronto, ON',
  slug: 'toronto-on',
  latitude: 43.6532,
  longitude: -79.3832,
  countryCode: CountryCodeList.CANADA,
};

export const defaultUSCityPayload = {
  name: 'Tampa, FL',
  slug: 'tampa-fl',
  latitude: 27.9506,
  longitude: -82.4572,
  countryCode: CountryCodeList.UNITED_STATES,
};
