/// <reference types="apple-mapkit-js-browser" />
import newGeoJsonPolygon from '../geo-json-polygon';

import type { GeoJsonPolygon } from '../types';

export default function geojsonPolygonFromMapkitBbox(bbox: mapkit.CoordinateRegion): GeoJsonPolygon {
  const { center, span } = bbox;
  const bottom = center.latitude - span.latitudeDelta / 2;
  const top = center.latitude + span.latitudeDelta / 2;
  const left = center.longitude - span.longitudeDelta / 2;
  const right = center.longitude + span.longitudeDelta / 2;
  return newGeoJsonPolygon(left, right, bottom, top);
}
