/**
 * `HTTP` defines a set of request methods to indicate the desired action to be performed for a given resource.
 *
 * See {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods|HTTP request methods} for more information.
 */
export enum HttpRequestMethodTypes {
  /**
   * The `GET` method requests a representation of the specified resource. Requests using GET should only retrieve data.
   */
  GET = 'GET',
  /**
   * The `HEAD` method asks for a response identical to that of a GET request, but without the response body.
   */
  HEAD = 'HEAD',
  /**
   * The `POST` method is used to submit an entity to the specified resource, often causing a change in state or side effects on the server.
   */
  POST = 'POST',
  /**
   * The `PUT` method replaces all current representations of the target resource with the request payload.
   */
  PUT = 'PUT',
  /**
   * The `PATCH` method is used to apply partial modifications to a resource.
   */
  PATCH = 'PATCH',
  /**
   * The `DELETE` method deletes the specified resource.
   */
  DELETE = 'DELETE',
  /**
   * The `OPTIONS` method is used to describe the communication options for the target resource.
   */
  OPTIONS = 'OPTIONS',
}
