import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ZoocasaIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 610.506 84.552" className={className} onClick={onClick}>
      <path d="M62.966 67.318V80.64H3.998v-3.931L40.143 21.89H8.693V8.567h54.164V12.5L26.602 67.318zm97.653-22.714c0 21.185-17.254 37.346-38.44 37.346S83.96 65.79 83.96 44.604c0-21.185 17.036-37.347 38.22-37.347s38.44 16.162 38.44 37.347zm-61.48 0c0 13.978 9.718 23.696 23.15 23.696 13.213 0 23.15-9.718 23.15-23.696 0-13.869-9.937-23.697-23.15-23.697-13.432 0-23.15 9.828-23.15 23.697zm164.294 0c0 21.185-17.254 37.346-38.439 37.346s-38.22-16.161-38.22-37.346c0-21.185 17.035-37.347 38.22-37.347s38.439 16.162 38.439 37.347zm-61.48 0c0 13.978 9.719 23.696 23.15 23.696 13.214 0 23.151-9.718 23.151-23.696 0-13.869-9.937-23.697-23.15-23.697-13.432 0-23.151 9.828-23.151 23.697zM357.06 25.166l-11.138 7.862c-4.805-7.644-11.576-12.12-20.64-12.12-13.431 0-23.26 9.827-23.26 23.805 0 13.869 9.829 23.587 23.26 23.587 9.064 0 15.835-4.477 20.64-12.121l11.138 7.862c-6.224 11.03-17.909 17.91-31.887 17.91-21.403 0-38.33-16.162-38.33-37.347 0-21.185 16.927-37.347 38.44-37.347 13.868 0 25.443 6.88 31.777 17.91zm158.965-4.259l-10.047 7.754c-3.494-5.242-7.862-8.3-13.759-8.3-4.805 0-8.954 2.184-8.954 6.99 0 4.149 3.166 5.896 8.408 8.299l8.19 3.603c11.357 5.024 17.8 10.811 17.8 21.076 0 13.431-10.92 21.621-25.226 21.621-12.449 0-22.495-6.333-27.846-15.943l10.156-7.644c3.931 5.46 9.063 10.483 17.69 10.483 6.334 0 10.265-2.949 10.265-7.972 0-4.695-3.494-6.333-9.173-8.953l-7.753-3.495c-10.265-4.696-17.472-10.592-17.472-20.748 0-12.449 10.374-20.42 23.915-20.42 11.466 0 19.547 5.787 23.806 13.65zM181.257 9.882a7.521 7.521 0 01-7.56 7.188c-3.828 0-7.561-3.36-7.561-7.188a7.53 7.53 0 017.56-7.28 7.353 7.353 0 017.56 7.28zm232.092-2.19a9.317 9.317 0 00-2.702-.435 8.782 8.782 0 00-2.577.397L376.03 80.64h15.834l4.04-9.392h29.485l4.04 9.392h15.835zm-12.203 51.435l9.5-24.242 9.501 24.242zM574.593 7.692a9.317 9.317 0 00-2.702-.435 8.782 8.782 0 00-2.577.397l-32.04 72.986h15.834l4.04-9.392h29.485l4.04 9.392h15.835zM562.39 59.127l9.5-24.242 9.501 24.242z"></path>
    </svg>
  );
};

export default ZoocasaIcon;
