import React, { createContext, useState, useContext, ReactNode } from 'react';

export type SnackbarData = {
  visible: boolean;
  message: ReactNode;
  isError?: boolean;
};

export type ISnackbarContext = {
  snackbarData: SnackbarData;
  setSnackbarData: React.Dispatch<React.SetStateAction<SnackbarData>>;
  closeSnackbar: () => void;
};

export const SnackbarContext = createContext<
  ISnackbarContext | Record<string, unknown>
>({});
export function useSnackbarContext <T extends ISnackbarContext | Record<string, unknown> = ISnackbarContext>() {
  return useContext(SnackbarContext) as T;
}

interface Props {
  children: ReactNode;
}

const defaultSnackbarData = {
  visible: false,
  message: '',
};

export default function SnackbarContextProvider({ children }: Props) {
  const [snackbarData, setSnackbarData] =
    useState<ISnackbarContext['snackbarData']>(defaultSnackbarData);

  const closeSnackbar = () =>
    setSnackbarData({ ...defaultSnackbarData, visible: false });

  return (
    <SnackbarContext.Provider
      value={{ snackbarData, setSnackbarData, closeSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
}

function useSnackbar(): ISnackbarContext {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error(
      'useSnackbar must be used within a SnackbarContextProvider'
    );
  }
  return context as Required<ISnackbarContext>;
}

export { useSnackbar };
