import React, { ReactNode, useState } from 'react';
import { useEffect } from 'react';
import { InView } from 'react-intersection-observer';

interface Props {
  children: ReactNode;
  onValueChange: (value: boolean) => void;
}

export default function LoadWhenVisible({ children, onValueChange }: Props) {
  const [active, setActive] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (window) {
      setShouldRender(true);
      if (!window['IntersectionObserver']) {
        require('intersection-observer');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global.window]);

  const visibilityChanged = (isVisible: boolean) => {
    if (isVisible && active) {
      onValueChange(isVisible);
      setActive(false);
    }
  };

  return !shouldRender ? null : (
    <InView onChange={visibilityChanged}>
      {children}
    </InView>
  );
}
