import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  isExpTheme?: boolean;
}

const TwitterIcon = ({ className, onClick, isExpTheme }: Props) => {
  return (isExpTheme
    ?
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      <g id="twitter-x" clipPath="url(#clip0_22012_45018)">
        <path id="Vector" d="M1.1663 1.26709L7.39703 9.59817L1.12695 16.3717H2.5381L8.02754 10.4414L12.4629 16.3717H17.265L10.6837 7.572L16.5199 1.26709H15.1087L10.0532 6.7288L5.96848 1.26709H1.1663ZM3.24149 2.30654H5.44762L15.1895 15.3321H12.9834L3.24149 2.30654Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_22012_45018">
          <rect width="16" height="16" fill="white" transform="translate(0.666016 0.761719)"/>
        </clipPath>
      </defs>
    </svg>

    :
    <svg xmlns="http://www.w3.org/2000/svg" width="240" height="240" fill="none" viewBox="0 0 240 240" className={className} onClick={onClick}>
      <circle cx="120" cy="120" r="120" fill="#fff"></circle>
      <path fill="#000" d="M191.623 187.498l-55.577-74.459 55.928-60.538h-12.587l-48.965 53.002L90.86 52.501H48.026l58.704 78.647-52.058 56.35H67.26l45.095-48.814 36.435 48.814h42.834zm-18.51-9.29h-19.678L66.539 61.793h19.678l86.896 116.415z"></path>
    </svg>
  );
};

export default TwitterIcon;
