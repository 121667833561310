import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const AccessibilityIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" baseProfile="tiny" version="1.2" viewBox="0 0 11.299 14.886" className={className} onClick={onClick}>
      <path fill="#010101" d="M11.271 3.656c-.117-.442-.619-.708-1.061-.59 0 0-3.361 1.09-4.57 1.09-1.178 0-4.509-1.061-4.509-1.061-.442-.117-.943.089-1.09.531-.147.471.118.972.59 1.09l3.625 1.09c.177.06.325.236.296.442-.03.59-.089 1.858-.207 2.329-.295 1.356-1.916 5.07-1.916 5.07a.875.875 0 00.767 1.238c.324 0 .648-.178.766-.501L5.7 10.848l1.651 3.567a.85.85 0 00.767.471.788.788 0 00.354-.088.853.853 0 00.412-1.15s-1.621-3.714-1.917-5.07c-.118-.472-.176-1.739-.206-2.329 0-.206.118-.382.295-.442l3.625-1.09c.443-.118.707-.62.59-1.061zM5.7 3.302a1.65 1.65 0 100-3.301 1.65 1.65 0 000 3.301z" ></path>
    </svg>
  );
};

export default AccessibilityIcon;
