export default function timeInterval(fromTime: string, toTime?: string) {
  const fromPrefix = getPrefix(fromTime);
  const fromSuffix = getSuffix(fromTime);
  if (!toTime) {
    return fromPrefix + fromSuffix;
  } else {
    const toPrefix = getPrefix(toTime);
    const toSuffix = getSuffix(toTime);
    if (fromSuffix === toSuffix) {
      return `${fromPrefix}-${toPrefix} ${toSuffix}`;
    } else {
      return `${fromPrefix}${fromSuffix} - ${toPrefix}${toSuffix}`;
    }
  }
}

function getPrefix(timeString: string) {
  return timeString.replace(/^0|[^\d:]/g, '').replace(/:00$/, '');
}

function getSuffix(timeString: string) {
  return timeString.replace(/[\d:\s]/g, '').toLowerCase();
}
