/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import LoadWhenVisible from 'components/load-when-visible';
import Image, { ImageProps } from './image';
import styles from './style.module.scss';

interface Props extends Omit<ImageProps, 'isVisible'> {
  className?: string;
  onClick?: () => void;
}

export default function ImageLoader({ className, onClick, ...imageProps }: Props) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <LoadWhenVisible onValueChange={setIsVisible}>
      <div className={`${styles.component} ${className}`} onClick={onClick}>
        <Image isVisible={isVisible} {...imageProps} />
      </div>
    </LoadWhenVisible>
  );
}
