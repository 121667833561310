import React, { createContext, ReactNode, useContext } from 'react';

/* import site themes here */
import { themes, ThemeName, ThemeConfig } from 'themes';
import MiddlewareManager from 'utils/fetchWithRetry';
import { createTenantHeaderMiddleware } from 'utils/tenant-injector-middleware';
import { themeNameOrDefault } from 'utils/themes';

/**
 * The name of the header used to select the theme via request headers.
 * This header is sent with the name of the theme.
 */
export const X_MULTITENANCY_THEME_HEADER_NAME = 'x-mulitenancy-theme';

export interface ITheme {
  themeName: ThemeName;
  theme: ThemeConfig;
  changeTheme: React.Dispatch<React.SetStateAction<string>>;
  originUrl: string;
}

export const ThemeContext = createContext<ITheme | Record<string, unknown>>({});

export function useThemeContext<T extends ITheme | Record<string, unknown> = ITheme>() {
  return useContext(ThemeContext) as T;
}

interface ThemeContextProviderProps {
  themeName?: string;
  originUrl: string;
  children: ReactNode;
}

export default function ThemeContextProvider({
  children,
  themeName: maybeThemeName,
  originUrl,
}: ThemeContextProviderProps) {
  const themeName = themeNameOrDefault(maybeThemeName);
  const theme = themes[themeName];

  //  Create a global middleware to automatically append the Tenant information into fetch requests to go-search
  const tenant = themeName;
  const tenantHeaderMiddleware = createTenantHeaderMiddleware(tenant);
  MiddlewareManager.setGlobalMiddleware(tenantHeaderMiddleware);
  
  return (
    <ThemeContext.Provider value={{ themeName, theme, themes, originUrl }}>
      <style>{themedStyle(theme)}</style>
      {children}
    </ThemeContext.Provider>
  );
}

const themedStyle = (theme: ThemeConfig) => {
  return `
    :root {
      // ------------------ Multi-Tenancy Variables -------------------
      --themes-colors-primary-very-light: ${theme.colors.primaryVeryLight};
      --themes-colors-primary-light: ${theme.colors.primaryLight};
      --themes-colors-primary: ${theme.colors.primary};
      --themes-colors-primary-dark: ${theme.colors.primaryDark};
      --themes-colors-primary-very-dark: ${theme.colors.primaryVeryDark};
      --themes-colors-primary-background: ${theme.colors.primaryBackground};
      --themes-colors-primary-font-color: ${theme.colors.primaryFontColor};
      --themes-colors-primary-for-sale: ${theme.colors.primaryForSale};
      --themes-colors-primary-sold: ${theme.colors.primarySold};
      --themes-colors-primary-filters: ${theme.colors.primaryFilters};
      --themes-colors-primary-link-color: ${theme.colors.primaryLinkColor};
      --themes-colors-primary-inverted-background: ${theme.colors.primaryInvertedBackground};
      --themes-colors-primary-border-color: ${theme.colors.primaryBorderColor};
      --themes-colors-primary-chart: ${theme.colors.primaryChart};
      --themes-colors-secondary-background: ${theme.colors.secondaryBackground};
      --themes-colors-secondary-border-color: ${theme.colors.secondaryBorderColor};
      --themes-colors-secondary-link-color: ${theme.colors.secondaryLinkColor};
      --themes-colors-tertiary-background: ${theme.colors.tertiaryBackground};
      --themes-colors-tertiary-border-color: ${theme.colors.tertiaryBorderColor};
      --themes-colors-tertiary-link-color: ${theme.colors.tertiaryLinkColor};
      --themes-colors-quaternary-link-color: ${theme.colors.quaternaryLinkColor};
      --themes-colors-transparent-background: ${theme.colors.transparentBackground};
      --themes-colors-map-pin-for-sale: ${theme.colors.mapPinForSale};
      --themes-buttons-border-radius: ${theme.buttons.borderRadius};
      --themes-buttons-border-radius-small: ${theme.buttons.borderRadiusSmall};
      --themes-buttons-border-radius-medium: ${theme.buttons.borderRadiusMedium};
      --themes-buttons-border-radius-large: ${theme.buttons.borderRadiusLarge};
      --themes-buttons-border-radius-modal: ${theme.buttons.borderRadiusModal};
      --themes-icons-border-radius: ${theme.icons.borderRadius};
      --themes-box-shadow: ${theme.decorations.boxShadow};
      --themes-box-shadow-light: ${theme.decorations.boxShadowLight};
      --themes-area-page-line-width: ${theme.decorations.areaPageLineWidth};
      --themes-fonts-heading: ${theme.fonts.heading};
      --themes-fonts-text: ${theme.fonts.text};
      --themes-fonts-heading-weight: ${theme.fonts.headingWeight};
      --themes-fonts-heading-bold-weight: ${theme.fonts.headingBoldWeight};
    }
  `;
};
