import React from 'react';
import ImageLoader from 'components/image-loader';
import Link from 'components/link';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';

interface Props {
  className?: string;
}

export default function BBBLogo({ className }: Props) {
  const bbbLink = 'https://www.bbb.org/ca/on/toronto/profile/real-estate-services/zoocasa-realty-inc-0107-1380462';
  return (
    <Link
      as={bbbLink}
      href={bbbLink}
      target="_blank"
      rel="noreferrer"
      className={buildClassName(styles.component, className)}
    >
      <ImageLoader src="/next/assets/images/bbb-logo.png" alt="Best Business Bureau" width="40px" />
    </Link>
  );
}
