import React, { createContext, ReactNode, useState, useEffect, useContext } from 'react';
import { useChat } from 'contexts/chat';

import type { ConnectionRequestOptions } from 'components/modal-dialog/connection-request';
import type { ConnectionRequestOptions as ConnectionRequestPopupOptions } from 'components/modal-dialog/connection-request-popup';
import type { ListingOptions } from 'components/modal-dialog/listing';
import type { NotificationOptions } from 'components/modal-dialog/notification';
import type { CreateSavedSearchOptions } from 'components/modal-dialog/create-saved-search';
import type { UserConfirmAccountOptions } from 'components/modal-dialog/user/confirm-account';
import type { UserResetPasswordOptions } from 'components/modal-dialog/user/reset-password';
import type { UserRegisterOptions, UserSignInOptions } from 'components/modal-dialog/user/login-registration';
import type { UserNewsletterConsentOptions } from 'components/modal-dialog/user/phone-number-and-email';
import type { UserForgotPasswordOptions } from 'components/modal-dialog/user/forgot-password';
import type { LightboxOptions } from 'components/modal-dialog/lightbox';
import type { DeleteOptions } from 'components/modal-dialog/delete-prompt';
import type { SavedSearchFiltersOptions } from 'components/modal-dialog/saved-search-filters';
import type { PhotoGalleryOptions } from 'components/modal-dialog/photo-gallery';
import type { ConnectWithAgentOptions } from 'components/modal-dialog/connect-with-an-agent';
import type { PreConPhotoGalleryOptions } from 'components/modal-dialog/precon-photo-gallery';
import type { PreconRequestOptions } from 'components/modal-dialog/precon-request';
import type { TermsOfUseOptions } from 'components/modal-dialog/terms-of-use';
import type { UserPrivacyTermsOptions } from 'components/modal-dialog/user/privacy-terms';
import type { ActivateAccountOptions } from 'components/modal-dialog/user/activate-account';
import type { QuebecPopupOptions } from 'components/modal-dialog/quebec-popup';
import type { SSOAuthProperties } from 'components/google-button';
import type { HomeAppraisalConnectionRequestOptions } from 'components/modal-dialog/home-appraisal-connection-request-popup';
import type { MyLinkMyLeadCreateOptions } from 'components/modal-dialog/my-link-my-lead-create';

export type OpenModal = (name: Modal['name'], options?: Modal['options']) => void;

export interface IModalContext {
  modals: Modal[];
  activeModal: Modal['name'] | null;
  openModal: OpenModal;
  closeModal: () => void;
  closeModalByName: (name: string) => void;
  closeAllModals: () => void;
  changeUrlOnModal: (customUrl: string) => void;
  isCrawler: boolean;
}

export const ModalContext = createContext<IModalContext | Record<string, unknown>>({});

export function useModalContext <T extends IModalContext | Record<string, unknown> = IModalContext>() {
  return useContext(ModalContext) as T;
}

interface Props {
  children: ReactNode;
  isCrawler: boolean;
}

export const LOGIN_REGISTRATION_MODAL = 'login-registration';
export const LISTING_MODAL = 'listing';
export const TERMS_OF_USE_MODAL = 'terms-of-use';
export const SAVED_SEARCH_FILTERS_MODAL = 'saved-search-filters';
export const CREATE_SAVED_SEARCH_MODAL = 'create-saved-search';
export const MY_LINK_MY_LEAD_CREATE_MODAL = 'my-link-my-lead';
export const NOTIFICATION_MODAL = 'notification';

export interface Modal {
  name: 'connection-request'
  | 'connection-request-popup'
  | typeof TERMS_OF_USE_MODAL
  | typeof LISTING_MODAL
  | typeof NOTIFICATION_MODAL
  | typeof CREATE_SAVED_SEARCH_MODAL
  | 'activate-account'
  | 'confirm-account'
  | 'forgot-password'
  | 'reset-password'
  | 'change-password'
  | 'create-password'
  | 'change-email'
  | typeof LOGIN_REGISTRATION_MODAL
  | 'phone-number'
  | 'phone-number-and-email'
  | 'newsletter'
  | 'privacy-terms'
  | 'lightbox'
  | 'delete-prompt'
  | typeof SAVED_SEARCH_FILTERS_MODAL
  | typeof MY_LINK_MY_LEAD_CREATE_MODAL
  | 'welcome'
  | 'school-filters'
  | 'photo-gallery'
  | 'connect-with-agent'
  | 'precon-photo-gallery'
  | 'precon-success-notification'
  | 'precon-request'
  | 'quebec-popup'
  | 'home-appraisal-connection-request-popup'
  | 'home-appraisal-connection-request';
  options: ConnectionRequestOptions
  | ConnectionRequestPopupOptions
  | MyLinkMyLeadCreateOptions
  | ListingOptions
  | NotificationOptions
  | CreateSavedSearchOptions
  | UserConfirmAccountOptions
  | UserResetPasswordOptions
  | UserSignInOptions
  | UserRegisterOptions
  | UserForgotPasswordOptions
  | UserPrivacyTermsOptions
  | ActivateAccountOptions
  | LightboxOptions
  | DeleteOptions
  | SavedSearchFiltersOptions
  | PhotoGalleryOptions
  | ConnectWithAgentOptions
  | PreConPhotoGalleryOptions
  | PreconRequestOptions
  | SSOAuthProperties
  | UserNewsletterConsentOptions
  | TermsOfUseOptions
  | QuebecPopupOptions
  | HomeAppraisalConnectionRequestOptions;
}

export default function ModalContextProvider({ children, isCrawler }: Props) {
  const [modals, setModals] = useState<IModalContext['modals']>([]);
  const { setPageContent } = useChat();
  const activeModal: IModalContext['activeModal'] = modals.length ? modals[modals.length - 1].name : null;

  const openModal: IModalContext['openModal'] = (name, options = {}) => {
    setModals(modals => [...modals, { name, options }]);
  };

  const changeUrlOnModal: IModalContext['changeUrlOnModal'] = url => {
    window.history.pushState({ ...window.history.state }, '', url);
  };

  const closeModal: IModalContext['closeModal'] = () => {
    activeModal === 'listing' && setPageContent(null);
    setModals(modals => modals.filter(modal => modal !== modals[modals.length - 1]));
  };

  const closeModalByName: IModalContext['closeModalByName'] = (name: string) => {
    setModals(modals => modals.filter(modal => modal.name !== name));
  };

  const closeAllModals: IModalContext['closeAllModals'] = () => {
    setPageContent(null);
    setModals([]);
  };

  const onPopState = () => {
    closeAllModals();
  };

  useEffect(() => {
    window.addEventListener('popstate', onPopState, { passive: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContext.Provider value={{ modals, activeModal, openModal, closeModal, closeModalByName, closeAllModals, changeUrlOnModal, isCrawler }}>
      {children}
    </ModalContext.Provider>
  );
}

function useModal(): IModalContext {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContextProvider');
  }
  return context as Required<IModalContext>;
}

export { useModal };