// Source: https://stackoverflow.com/a/49780469

type ScriptLoad = {
  source: string;
  beforeEl?: HTMLScriptElement;
  async?: boolean;
  defer?: boolean;
  append?: boolean;
};

export function loadScript({ source, beforeEl, async = true, defer = true, append = false }: ScriptLoad) {
  return new Promise<void>(resolve => {
    if (getScriptElement(source)) {
      return resolve();
    }

    const script = document.createElement('script');
    script.src = source;
    script.async = async;
    script.defer = defer;
    script.onload = function() {
      script.onload = null;
      resolve();
    };

    if (append) {
      appendElement(script);
    } else {
      insertBeforeElement(script, beforeEl);
    }
  });
}

export function loadStylesheet(source: string, beforeEl?: HTMLLinkElement) {
  return new Promise<void>(resolve => {
    if (document.querySelector(`link[href*="${source}"]`)) {
      return resolve();
    }

    const link = document.createElement('link');
    link.href = source;
    link.rel = 'stylesheet';
    link.onload = function() {
      link.onload = null;
      resolve();
    };

    insertBeforeElement(link, beforeEl);
  });
}

export function removeScript(source: string) {
  const element = getScriptElement(source);
  if (element) {
    element.remove();
  }
}

export function loadJQuery() {
  return loadScript({ source: 'https://code.jquery.com/jquery-3.4.1.min.js' });
}

function getScriptElement(source: string) {
  return document.querySelector(`script[src*="${source}"]`);
}

function insertBeforeElement<T extends HTMLScriptElement | HTMLLinkElement>(element: T, beforeEl?: T) {
  const prior = beforeEl || document.getElementsByTagName(element.nodeName)[0] as T | undefined;
  if (prior && prior.parentNode) {
    prior.parentNode.insertBefore(element, prior);
  }
}

function appendElement<T extends HTMLScriptElement | HTMLLinkElement>(element: T) {
  const prior = document.getElementsByTagName(element.nodeName)[0] as T | undefined;
  if (prior && prior.parentNode) {
    prior.parentNode.appendChild(element);
  }
}
