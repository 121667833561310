const keywordsUS = [
  {
    label: 'Sold Prices',
    link: '/sold',
    type: 'area',
  },
  {
    label: 'Houses for Sale',
    link: '/houses',
    type: 'area',
  },
  {
    label: 'Condos for Sale',
    link: '/condos',
    type: 'area',
  },
  {
    label: 'Townhouses for Sale',
    link: '/townhouses',
    type: 'area',
  },
  {
    label: 'Home Appraisal',
    link: '/home-appraisal',
    type: 'other',
  },
  {
    label: 'Find an Agent',
    link: '/agents-search',
    type: 'other',
  },
  {
    label: 'Duplexes',
    link: '/search?condo=false&duplex=true',
    type: 'search',
  },
  {
    label: 'Detached Houses',
    link: '/search?attached=false&condo=false&semidetached=false&townhouse=false',
    type: 'search',
  },
  {
    label: '3-Bed Houses',
    link: '/search?bedrooms=3&condo=false',
    type: 'search',
  },
  {
    label: '2-Bed Condos',
    link: '/search?attached=false&bedrooms=2&detached=false&semidetached=false&townhouse=false',
    type: 'search',
  },
  {
    label: '1-Bed Condos',
    link: '/search?attached=false&bedrooms=1&detached=false&semidetached=false&townhouse=false',
    type: 'search',
  },
  {
    label: 'Condos for Rent',
    link: '/filter?rental=true&townhouse=false&house=false',
    type: 'area',
  },
  {
    label: 'Houses for Rent',
    link: '/filter?rental=true&townhouse=false&condo=false',
    type: 'area',
  },
  {
    label: 'Condos Under $500K',
    link: '/search?attached=false&detached=false&price-max=500000&semidetached=false&townhouse=false',
    type: 'search',
  },
  {
    label: 'Houses Under $1 mil',
    link: '/search?condo=false&price-max=1000000&townhouse=false',
    type: 'search',
  },
  {
    label: 'Condos > $1,000,000',
    link: '/search?attached=false&detached=false&price-min=1000000&semidetached=false&townhouse=false',
    type: 'search',
  },
  {
    label: 'Search by Schools',
    link: '/search?show-schools=true',
    type: 'search',
  },
  {
    label: 'Most Expensive Houses',
    link: '/search?condo=false&sort=-price&townhouse=false',
    type: 'search',
  },
];

export default keywordsUS;