import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const YoutubeIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" className={className} onClick={onClick}>
      <g clipPath="url(#clip0_22012_45029)">
        <path
          d="M17.0929 5.57672C17.0038 5.22078 16.8224 4.89465 16.5669 4.63127C16.3114 4.3679 15.991 4.17661 15.6379 4.07672C14.3479 3.76172 9.18793 3.76172 9.18793 3.76172C9.18793 3.76172 4.02793 3.76172 2.73793 4.10672C2.38486 4.20661 2.06441 4.3979 1.80894 4.66127C1.55347 4.92465 1.37202 5.25078 1.28293 5.60672C1.04684 6.91589 0.931356 8.24395 0.937929 9.57422C0.929513 10.9145 1.045 12.2527 1.28293 13.5717C1.38115 13.9166 1.56666 14.2303 1.82154 14.4826C2.07641 14.7348 2.39204 14.9171 2.73793 15.0117C4.02793 15.3567 9.18793 15.3567 9.18793 15.3567C9.18793 15.3567 14.3479 15.3567 15.6379 15.0117C15.991 14.9118 16.3114 14.7205 16.5669 14.4572C16.8224 14.1938 17.0038 13.8677 17.0929 13.5117C17.3272 12.2124 17.4427 10.8945 17.4379 9.57422C17.4463 8.23393 17.3309 6.89574 17.0929 5.57672Z"
          stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 12.0261L11.8125 9.57359L7.5 7.12109V12.0261Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_22012_45029">
          <rect width="18" height="18" fill="white" transform="translate(0.1875 0.761719)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default YoutubeIcon;
