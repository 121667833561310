import { PropertyTypeFilter } from 'contexts/preferences/listing-params';

export const listingComponentHeights = {
  marketStats: {
    desktop: '635px',
    mobile: '1165px',
    noData: '0px',
    modal: '1490px',
  },
};

export const defaultHomeType: PropertyTypeFilter = {
  house: true,
  townhouse: true,
  condo: true,
  land: true,
  commercial: true,
  farm: true,
};
